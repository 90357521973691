import errorApiResponse from 'assets/images/ErrorApiResponse.png';
import { Button } from 'components/Button/Button';
import { Dialog } from 'components/Dialog/Dialog';
import { useIntl } from 'react-intl';

import {
  DialogBodyContainer,
  DialogErrorMessageContainer,
  ErrorApiResponseImage,
} from './ApiErrorDialog.style';

type Props = {
  isOpen: boolean;
  onClose?: () => void;
};

export const ApiErrorDialog = ({ isOpen, onClose }: Props) => {
  const intl = useIntl();
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={intl.formatMessage({ id: 'api-error-dialog.title' })}
    >
      <DialogBodyContainer>
        <ErrorApiResponseImage src={errorApiResponse} />
        <DialogErrorMessageContainer>
          {intl.formatMessage({ id: 'api-error-dialog.error-message' })}
        </DialogErrorMessageContainer>
        <Button displayStyle="filledOrange" onClick={onClose} bigPaddings>
          {intl.formatMessage({ id: 'api-error-dialog.close-button' })}
        </Button>
      </DialogBodyContainer>
    </Dialog>
  );
};
