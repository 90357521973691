export type GoogleAnalyticsTagConfig = {
  action: string;
  label: string;
  category: string;
};

export const GOOGLE_ANALYTICS_CATEGORIES = {
  DELIVERY_NOTES: 'Ticket',
  HISTORY: 'History',
  INVOICES: 'Invoices',
  CLIENT_COMPANY: 'Client company',
  CLIENT_COMPANY_USERS: 'Client company users',
  SITE_DOCUMENTS: 'Site documents',
  CLIENT_COMPANIES: 'Client companies',
  CLIENT_REQUESTS: 'Client requests',
  BUSINESS_ADMIN_CLIENT_REQUESTS: 'Business Admin Client requests',
  CLIENT_REQUEST_DOCUMENTS: 'Client request documents',
  CLIENT_REQUEST_INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE:
    'Client request inert waste application for prior acceptance',
  HELP_AND_CONTACT: 'Help and contact',
  GDPR: 'GDPR',
  HOME: 'Home',
  SITE_LOCATIONS: 'Site Locations',
  SITE_AND_DOCUMENT: 'Site And Document',
  SUBSCRIPTION_REQUEST: 'Subscription request',
  BUSINESS_REFERENTS: 'Business Referents',
};

export const GOOGLE_ANALYTICS_LABELS = {
  PREVIEW_BUTTON_CLICK: 'Click on preview button',
  DOWNLOAD_BUTTON_CLICK: 'Click on download button',
  PAGE_VIEW: 'Page view',
  ACTION_SUCCESS: 'Action success',
  CLICK: 'Click',
  REDIRECT_BUTTON_CLICK: 'Click on redirect button',
};

export const GOOGLE_ANALYTICS_ACTIONS = {
  //Home
  HOME_PAGE_VIEW: 'Home_Pg',

  //DeliveryNotes
  HISTORY_PAGE: 'Hist_Pg',
  DELIVERY_NOTES_PAGE_VIEW: 'Hist_Pg_Tikt_Tab',
  DELIVERY_NOTES_PREVIEW_BUTTON_CLICK: 'Hist_Pg_Tikt_Tab_ViewPdf_Btn',
  DELIVERY_NOTES_DOWNLOAD_BUTTON_CLICK: 'Hist_Pg_Tikt_Tab_DwnldPdf_Btn',
  DELIVERY_NOTES_EXPORT_BUTTON_CLICK: 'Hist_Pg_Tikt_Tab_Export_Btn',
  DELIVERY_NOTES_BULK_DOWNLOAD_BUTTON_CLICK: 'Hist_Pg_Tikt_Tab_BlkDwnld_Btn',
  DELIVERY_NOTES_FILTERS: 'Hist_Pg_Tikt_Tab_Filtr_Apply_Btn',

  //Invoices
  INVOICES_PAGE_VIEW: 'Hist_Pg_Invc_Tab',
  INVOICES_PREVIEW_BUTTON_CLICK: 'Hist_Pg_Invc_Tab_ViewPdf_Btn',
  INVOICES_DOWNLOAD_BUTTON_CLICK: 'Hist_Pg_Invc_Tab_DwnldPdf_Btn',
  INVOICES_EXPORT_BUTTON_CLICK: 'Hist_Pg_Invc_Tab_Export_Btn',
  INVOICES_BULK_DOWNLOAD_BUTTON_CLICK: 'Hist_Pg_Invc_Tab_BlkDwnld_Btn',
  INVOICES_FILTERS: 'Hist_Pg_Invc_Tab_Filtr_Apply_Btn',
  INVOICES_GO_TO_LINKED_DELIVERY_NOTE_BUTTON_CLICK: 'Hist_Pg_Invc_Tab_AssocTikt_Btn',

  //ClientCompany
  CLIENT_COMPANY_PAGE_VIEW: 'Cust_Pg',
  CLIENT_COMPANY_NEW_REQUEST_SENT: 'Cust_Pg_NewMsg_Send_Btn',
  CLIENT_COMPANY_NEW_REQUEST_MESSAGE_SENT: 'Cust_Pg_NewMsg_Sent_Btn',
  CLIENT_COMPANY_USERS_VIEW: 'Cust_Pg_CustUsr_Pg',
  CLIENT_COMPANY_BUSINESS_REFERENTS_VIEW: 'Cust_Pg_Cust_SalesPersns_Pg',

  //BusinessReferents
  BUSINESS_REFERENTS_DEACTIVATE_CONFIRM: 'Cust_Pg_SalesPrsn_Pg_Delete_Btn',

  //ClientCompanyUsers
  CLIENT_COMPANY_USERS_PAGE_VIEW: 'CustUsr_Pg',
  CLIENT_COMPANY_USERS_CUSTOMER_USER_INVITE: 'Cust_Pg_Cust_InviteCreate_Btn',
  CLIENT_COMPANY_USERS_CUSTOMER_USER_DELETE: 'CustUsr_Pg_CustUsrDelete_Btn',

  //ClientCompanies
  CLIENT_COMPANIES_PAGE_VIEW: 'Cust_Pg',
  CLIENT_COMPANIES_FILTERS: 'Cust_Pg_Cust_FiltrApply_Btn',
  CLIENT_COMPANIES_CUSTOMER_INVITE_CANCEL: 'Cust_Pg_Cust_InviteCancel_Btn',
  CLIENT_COMPANIES_CUSTOMER_INVITE_CREATION: 'Cust_Pg_Cust_InviteCreate_Btn',

  //BusinessAdminClientRequests
  BUSINESS_ADMIN_CLIENT_REQUESTS_PAGE_VIEW: 'BussAdmin_Msg_Pg',
  BUSINESS_ADMIN_CLIENT_REQUEST_INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_PAGE_VIEW:
    'BusinessAdminclientrequestinertwasteapplicationforprioracceptance_page',

  //BusinessReferents
  BUSINESS_REFERENTS_PAGE_VIEW: 'Cust_Pg_Cust_SalesPersns_Pg',

  //ClientRequests
  CLIENT_REQUESTS_PAGE_VIEW: 'Msgs_Pg',
  CLIENT_REQUESTS_FILTERS: 'Msgs_Pg_FiltrApply_Btn',

  //ClientRequestDocuments
  CLIENT_REQUEST_DOCUMENTS_PAGE_VIEW: 'Msgs_Pg_MsgsDoc_Pg',

  //ClientRequestInertWasteApplicationForPriorAcceptance
  CLIENT_REQUEST_INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_PAGE_VIEW:
    'Clientrequestinertwasteapplicationforprioracceptance_page',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_FILTERS:
    'InertWasteApplicationForPriorAcceptance_filters',

  //SiteDocuments
  SITE_DOCUMENTS_PAGE_VIEW: 'Site&Doc_Pg',
  SITE_DOCUMENTS_PREVIEW_BUTTON_CLICK: 'Site&Doc_Pg_Doc_Tab_View_Btn',
  SITE_DOCUMENTS_DOWNLOAD_BUTTON_CLICK: 'Site&Doc_Pg_Doc_Tab_Dwnld_Btn',
  SITE_DOCUMENTS_DELETE_COMFIRM: 'Site&Doc_Pg_Doc_Tab_Doc_Delete_Btn',
  SITE_DOCUMENTS_PUBLISH: 'Site&Doc_Pg_Doc_Tab_Doc_Publish_Btn',
  SPECIFIC_SITE_DOCUMENTS_PREVIEW_BUTTON_CLICK: 'Site&Doc_Pg_Doc_Tab_DocView_Btn',
  SPECIFIC_SITE_DOCUMENTS_DOWNLOAD_BUTTON_CLICK: 'Site&Doc_Pg_Doc_Tab_DocDwnld_Btn',

  //SiteLocation
  SITE_LOCATIONS_PAGE_VIEW: 'Site&Doc_Pg_Site_Tab',
  SITE_LOCATIONS_DISPLAY_SITE_DOCUMENTS_BUTTON_CLICK: 'Site&Doc_Pg_Site_Tab_SiteDoc_ViewDoc_Btn',
  SITE_LOCATIONS_ADDRESS_SELECTION: 'Site&Doc_Pg_Site_Tab_DirectnSrch_Btn',
  SITE_LOCATIONS_ACTIVITY_SELECTION: 'Site&Doc_Pg_Site_Tab_ActivtySlct_Drpdwn',

  //GDPR
  GDPR_DOCUMENT_CLICK: 'Profile_GDPR_Click',
  GDPR_EMAIL_CLICK: 'Profile_GDPR_email',

  //HelpAndContact
  HELP_AND_CONTACT_EMAIL_CLICK: 'Help&Contact_Pg_Panel_Email_lnk',
  HELP_AND_CONTACT_PANEL_VIEW: 'Help&Contact_Pg_Panel',

  //ShadowMode
  SHADOW_DELIVERY_NOTES_PAGE_VIEW: 'Hist_Pg_CustShdw_Tikt_Tab',
  SHADOW_INVOICES_PAGE_VIEW: 'Hist_Pg_CustShdw_Invc_Tab',

  //SubscriptionRequest
  SUBSCRIPTION_REQUEST_SUBMISSION_SUCCESS: 'New_Extrnl_Access_Rqust',
};
