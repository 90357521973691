import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { PERSIST, persistStore } from 'redux-persist';
import { FLUSH, PAUSE, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants';

import { rootReducer } from './reducers';

export const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
  reducer: rootReducer,
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
