import styled from 'styled-components';
import { getSpacing, typography } from 'stylesheet';

export const EmptyApiResponseContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
`;

export const EmptyApiResponseImage = styled.img``;

export const EmptyApiResponseTitle = styled.h3`
  margin-top: ${getSpacing(2)};
  ${typography.title_small};
  text-align: center;
`;

export const EmptyApiResponseDescription = styled.h3`
  margin-top: ${getSpacing(3)};
  text-align: center;
`;
