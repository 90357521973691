import styled from 'styled-components';

import { colorPalette } from '../../../stylesheet';

export const ClientRequestNotificationIconContainer = styled.span`
  position: relative;
`;

type ClientRequestCounterBubbleProps = {
  isLateralMenuIcon: boolean;
};

export const ClientRequestsCounterBubble = styled.span<ClientRequestCounterBubbleProps>`
  position: absolute;
  background-color: ${colorPalette.secondary_500};
  color: ${colorPalette.greyMain};
  top: ${({ isLateralMenuIcon }) => (isLateralMenuIcon ? '-8px' : '0px')};
  left: ${({ isLateralMenuIcon }) => (isLateralMenuIcon ? '10px' : '90px')};
  width: ${({ isLateralMenuIcon }) => (isLateralMenuIcon ? '15px' : '40px')};
  height: ${({ isLateralMenuIcon }) => (isLateralMenuIcon ? '15px' : '40px')};
  font-size: ${({ isLateralMenuIcon }) => (isLateralMenuIcon ? '10px' : '25px')};
  text-align: center;
  border-radius: 5px;
`;
