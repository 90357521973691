import { Loader } from 'components/Loader/Loader';
import { ReactElement } from 'react';

import { ApiResponseStatusSectionDisplayContainer } from './ApiResponseStatusSectionDisplay.style';
import { EmptyApiResponse, EmptyStatusImageProps } from './EmptyApiResponse/EmptyApiResponse';
import { EmptySearchApiResponse } from './EmptySearchApiResponse/EmptySearchApiResponse';
import { ErrorApiResponse } from './ErrorApiResponse/ErrorApiResponse';

export enum ApiResponseStatus {
  ERROR = 'error',
  LOADING = 'loading',
  EMPTY = 'empty',
  EMPTY_SEARCH = 'emptySearch',
  SUCCESS = 'success',
}

export type ApiResponseStatusSectionDisplayConfiguration = {
  emptyStatusTitle?: string;
  emptyStatusDescription?: string;
  emptyStatusButtonLabel?: string;
  onEmptyStatusButtonClick?: () => void;
  emptySearchStatusTitle?: string;
  errorStatusTitle?: string;
  errorStatusDescription?: string;
  errorStatusButtonLabel?: string;
  onErrorStatusButtonClick?: () => void;
  emptyStatusImageProps?: EmptyStatusImageProps;
};

type Props = {
  status: ApiResponseStatus;
  configuration?: ApiResponseStatusSectionDisplayConfiguration;
  children?: ReactElement;
};

export const ApiResponseStatusSectionDisplay = ({
  status,
  configuration,
  children,
}: Props): JSX.Element => {
  switch (status) {
    case ApiResponseStatus.LOADING:
      return (
        <ApiResponseStatusSectionDisplayContainer>
          <Loader />
        </ApiResponseStatusSectionDisplayContainer>
      );
    case ApiResponseStatus.EMPTY:
      return (
        <ApiResponseStatusSectionDisplayContainer>
          <EmptyApiResponse
            title={configuration?.emptyStatusTitle}
            description={configuration?.emptyStatusDescription}
            buttonLabel={configuration?.emptyStatusButtonLabel}
            onButtonClick={configuration?.onEmptyStatusButtonClick}
            emptyStatusImageProps={configuration?.emptyStatusImageProps}
          />
        </ApiResponseStatusSectionDisplayContainer>
      );
    case ApiResponseStatus.EMPTY_SEARCH:
      return (
        <ApiResponseStatusSectionDisplayContainer>
          <EmptySearchApiResponse title={configuration?.emptySearchStatusTitle} />
        </ApiResponseStatusSectionDisplayContainer>
      );
    case ApiResponseStatus.ERROR:
      return (
        <ApiResponseStatusSectionDisplayContainer>
          <ErrorApiResponse
            title={configuration?.errorStatusTitle}
            description={configuration?.errorStatusDescription}
            buttonLabel={configuration?.errorStatusButtonLabel}
            onButtonClick={configuration?.onErrorStatusButtonClick}
          />
        </ApiResponseStatusSectionDisplayContainer>
      );
    default:
      return <>{children}</>;
  }
};
