import { Dialog as MuiDialog } from '@material-ui/core/';
import { CloseIcon } from 'assets/icons/CloseIcon';
import { IconButton } from 'components/IconButton/IconButton';
import { ReactNode } from 'react';

import {
  StyledDialogContent,
  StyledDialogHeader,
  TitleContainer,
  TitleIconContainer,
  useDialogStyle,
} from './Dialog.style';

export type DialogStyleProps = {
  widthInPercent?: number;
  heightInPercent?: number;
};
type Props = {
  isOpen: boolean;
  titleIcon?: ReactNode;
  onClose?: () => void;
  title?: string;
  titleIconColor?: 'grey' | 'orange';
  showCloseIcon?: boolean;
  children: ReactNode;
} & DialogStyleProps;

export const Dialog = ({
  isOpen,
  titleIcon,
  onClose,
  title,
  titleIconColor = 'grey',
  showCloseIcon = false,
  widthInPercent,
  heightInPercent,
  children,
}: Props): JSX.Element => {
  const classes = useDialogStyle({ widthInPercent, heightInPercent })();
  return (
    <MuiDialog open={isOpen} onClose={onClose} scroll="paper" classes={classes}>
      <StyledDialogHeader>
        {titleIcon && (
          <TitleIconContainer titleIconColor={titleIconColor}>{titleIcon}</TitleIconContainer>
        )}
        <TitleContainer showCloseIcon={showCloseIcon}>{title}</TitleContainer>
        {onClose && showCloseIcon && (
          <IconButton size="medium" displayStyle="grey" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </StyledDialogHeader>
      <StyledDialogContent>{children}</StyledDialogContent>
    </MuiDialog>
  );
};
