import { apiClient } from '../apiClient';
import { CUSTOMER_AGREEMENT_VERSION } from '../constants';
import { ApiActionResponse } from '../types';
import {
  CreateCustomerAgreementRequestBody,
  PartialCreateCustomerAgreementRequestBody,
} from './types';

export const createCustomerAgreement = (
  partialCustomerAgreementRequestBody: PartialCreateCustomerAgreementRequestBody,
) => {
  const data: CreateCustomerAgreementRequestBody = {
    ...partialCustomerAgreementRequestBody,
    version: CUSTOMER_AGREEMENT_VERSION,
  };
  return apiClient.request<ApiActionResponse>('POST', '/customer-agreement', data);
};
