import 'dayjs/locale/fr';

import { MsalProvider } from '@azure/msal-react';
import { StylesProvider, ThemeProvider } from '@material-ui/core';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { App } from 'App';
import { AppHelmet } from 'AppHelmet';
import enMessages from 'assets/translations/en.json';
import frMessages from 'assets/translations/fr.json';
import { AppCrashFallback } from 'components/AppCrashFallback/AppCrashFallback';
import { CSSReset } from 'components/CSSReset/CSSReset.style';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import dayjs from 'dayjs';
import { muiTheme } from 'muiTheme';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { persistor, store } from 'redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { analytics } from 'services/analytics/analytics';
import { PLATFORM_LANGUAGE } from 'services/api/constants';
import { msalInstance } from 'services/authentication/msalConfig';
import { flattenMessages } from 'services/i18n/intl';
import { logger } from 'services/logs/logger';

import reportWebVitals from './reportWebVitals';
import { reactPlugin } from './services/logs/ApplicationInsights';

const locales: any = {
  fr: flattenMessages(frMessages),
  en: flattenMessages(enMessages),
};

const globalLocale = PLATFORM_LANGUAGE;

dayjs.locale(globalLocale);

(async () => {
  logger.init();

  try {
    analytics.init();
  } catch (error) {
    logger.logWarn('Error during Google Analytics initialization', error);
  }

  ReactDOM.render(
    <ErrorBoundary FallbackComponent={AppCrashFallback} hasError={false} eventId="">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <CSSReset />
          <MsalProvider instance={msalInstance}>
            {/* This provider is necessary to be able to override react material style */}
            <StylesProvider injectFirst>
              <IntlProvider locale={globalLocale} messages={locales[globalLocale]}>
                <ThemeProvider theme={muiTheme}>
                  <AppInsightsContext.Provider value={reactPlugin}>
                    <AppHelmet />
                    <App />
                  </AppInsightsContext.Provider>
                </ThemeProvider>
              </IntlProvider>
            </StylesProvider>
          </MsalProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>,
    document.getElementById('root'),
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
