import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const PageContent = styled.div`
  padding: ${getSpacing(8)} ${getSpacing(4)};
`;

export const Title = styled.h1`
  font-weight: bold;
`;

export const HelperList = styled.ul`
  list-style: disc inside;
  margin-top: ${getSpacing(2)};
`;
