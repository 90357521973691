import emptyApiResponse from 'assets/images/WarningLogo.png';
import { Button } from 'components/Button/Button';
import { Title } from 'pages/Home/HomePage.style';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { ROUTES } from 'routing/routes';

import {
  NotFoundImage,
  NotFoundPageContainer,
  NotFoundTextContainer,
  StyledLink,
} from './NotFoundPage.style';

export const NotFoundPage: FunctionComponent = () => (
  <NotFoundPageContainer>
    <NotFoundTextContainer>
      <NotFoundImage src={emptyApiResponse} />
      <Title>
        <FormattedMessage id="not-found.title" />
      </Title>

      <StyledLink to={ROUTES.HOME}>
        <Button color="primary" displayStyle="filledOrange" bigPaddings>
          <FormattedMessage id="not-found.go-to-home" />
        </Button>
      </StyledLink>
    </NotFoundTextContainer>
  </NotFoundPageContainer>
);
