import { useCallback } from 'react';
import { useIntl } from 'react-intl';

export const useIntlByKey = (intlRootKey: string) => {
  const intl = useIntl();

  const intlByKey = useCallback(
    (key: string, params: { [key: string]: string | number | undefined } = {}) => {
      return intl.formatMessage({ id: `${intlRootKey}.${key}` }, params);
    },
    [intl, intlRootKey],
  );

  return intlByKey;
};
