import { getSizeInPx } from 'stylesheet';

import { SvgIcon } from './SvgIcon';
import { IconProps } from './types';

export const CloseIcon = ({ widthInPx, heightInPx, color }: IconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 25 24"
    style={{
      width: getSizeInPx(widthInPx ?? 25),
      height: getSizeInPx(heightInPx ?? 24),
    }}
  >
    <path
      d="M3.22189 0.469768C2.59553 -0.156589 1.58001 -0.156589 0.953653 0.469768C0.327298 1.09613 0.327298 2.11165 0.953653 2.73801L10.2157 12L0.953749 21.262C0.327394 21.8883 0.327394 22.9039 0.953749 23.5302C1.5801 24.1566 2.59563 24.1566 3.22198 23.5302L12.4839 14.2683L21.7458 23.5302C22.3721 24.1566 23.3877 24.1566 24.014 23.5302C24.6404 22.9039 24.6404 21.8883 24.014 21.262L14.7521 12L24.0141 2.73801C24.6405 2.11165 24.6405 1.09613 24.0141 0.469768C23.3878 -0.156589 22.3722 -0.156589 21.7459 0.469768L12.4839 9.73181L3.22189 0.469768Z"
      fill={color ?? 'currentColor'}
    />
  </SvgIcon>
);
