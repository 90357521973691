import styled from 'styled-components';
import { typography } from 'stylesheet';

export const UserCardContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const UserInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: '4px';
`;

export const UserNameContainer = styled.div`
  ${typography.title_xsmall}
  text-transform: capitalize;
`;
export const UserRoleContainer = styled.div`
  ${typography.p2_regular}
`;
