import { getSizeInPx } from 'stylesheet';

import { SvgIcon } from './SvgIcon';
import { IconProps } from './types';

export const HomeIcon = ({ widthInPx, heightInPx, color }: IconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 19 21"
    style={{
      width: getSizeInPx(widthInPx ?? 19),
      height: getSizeInPx(heightInPx ?? 21),
    }}
  >
    <path
      d="M17.5 7.00001L11.5 1.74001C10.95 1.24805 10.2379 0.976074 9.49997 0.976074C8.76204 0.976074 8.04999 1.24805 7.49997 1.74001L1.49997 7.00001C1.18234 7.28408 0.928873 7.63256 0.756445 8.02225C0.584016 8.41194 0.496585 8.83389 0.499973 9.26001V18C0.499973 18.7957 0.816043 19.5587 1.37865 20.1213C1.94126 20.6839 2.70432 21 3.49997 21H15.5C16.2956 21 17.0587 20.6839 17.6213 20.1213C18.1839 19.5587 18.5 18.7957 18.5 18V9.25001C18.5019 8.82557 18.4138 8.40555 18.2414 8.01769C18.0691 7.62983 17.8163 7.28296 17.5 7.00001ZM11.5 19H7.49997V14C7.49997 13.7348 7.60533 13.4804 7.79287 13.2929C7.9804 13.1054 8.23476 13 8.49997 13H10.5C10.7652 13 11.0195 13.1054 11.2071 13.2929C11.3946 13.4804 11.5 13.7348 11.5 14V19ZM16.5 18C16.5 18.2652 16.3946 18.5196 16.2071 18.7071C16.0195 18.8946 15.7652 19 15.5 19H13.5V14C13.5 13.2044 13.1839 12.4413 12.6213 11.8787C12.0587 11.3161 11.2956 11 10.5 11H8.49997C7.70432 11 6.94126 11.3161 6.37865 11.8787C5.81604 12.4413 5.49997 13.2044 5.49997 14V19H3.49997C3.23476 19 2.9804 18.8946 2.79287 18.7071C2.60533 18.5196 2.49997 18.2652 2.49997 18V9.25001C2.50015 9.10802 2.53057 8.9677 2.58919 8.83839C2.64781 8.70907 2.7333 8.59372 2.83997 8.50001L8.83997 3.25001C9.02246 3.08969 9.25706 3.00127 9.49997 3.00127C9.74288 3.00127 9.97748 3.08969 10.16 3.25001L16.16 8.50001C16.2666 8.59372 16.3521 8.70907 16.4108 8.83839C16.4694 8.9677 16.4998 9.10802 16.5 9.25001V18Z"
      fill={color ?? 'currentColor'}
    />
  </SvgIcon>
);
