import { apiFilterQueryParamGenerator } from 'services/apiFilterQueryParamGenerator/apiFilterQueryParamGenerator';

import { apiClient } from '../apiClient';
import { getClientCompanySingleLegalCorporationId } from '../common';
import { PLATFORM_LEGAL_CORPORATION_ID } from '../constants';
import { BUSINESS_ADMIN_ROLE_CODE } from '../roles/types';
import { ApiActionResponse, ApiHydraPageableResponse } from '../types';
import {
  ApiPermissionFromUserMetadataItem,
  ApiPermissionInList,
  ApiPermissionOfLegalCorporation,
} from './types';

const ENDPOINT_PERMISSIONS_FROM_USERMETADATA = '/get-permissions-from-usermetadata';

export const fetchUserPermissions = () => {
  return apiClient.request<ApiPermissionInList[]>(
    'GET',
    `/me/permissions?legalCorporationId=${PLATFORM_LEGAL_CORPORATION_ID}`,
  );
};

export const desactivatePermission = (permissionId: number) => {
  return apiClient.request<ApiActionResponse>(
    'POST',
    `/permission/desactivate-permission?id=${permissionId}`,
  );
};

export const editPermissionRole = (permissionId: number, role: string) => {
  return apiClient.request<ApiPermissionInList>(
    'PUT',
    `/permission/edit-permission-role/${permissionId}?role=${role}`,
  );
};

export const getActiveBusinessAdminPermissionsOnLegalCorporation = () => {
  const legalCorporationId = getClientCompanySingleLegalCorporationId(
    PLATFORM_LEGAL_CORPORATION_ID,
  );
  return apiClient.request<ApiHydraPageableResponse<ApiPermissionOfLegalCorporation>>(
    'GET',
    `/legal-corporations/${legalCorporationId}/permissions?isActive=true&role.code=${BUSINESS_ADMIN_ROLE_CODE}`,
  );
};

export const getActivePermissionFromUserMetadataIdOnLegalCorporation = (
  userMetadataId: string,
  legalCorporationId: string,
) => {
  const filters = {
    'userMetadata.id': userMetadataId,
    'legalCorporation.id': legalCorporationId,
    isActive: '1',
  };
  return apiClient.request<ApiHydraPageableResponse<ApiPermissionFromUserMetadataItem>>(
    'GET',
    `${ENDPOINT_PERMISSIONS_FROM_USERMETADATA}${apiFilterQueryParamGenerator.format({
      filters,
    })}`,
  );
};
