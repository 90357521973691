import styled from 'styled-components';
import { frameHeight, getSpacing } from 'stylesheet';

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${frameHeight.header};
  padding: ${getSpacing(2)} ${getSpacing(2)} ${getSpacing(2)} ${getSpacing(5)};
`;

export const LogoContainer = styled.div`
  display: flex;
`;

export const HeaderBrandImageContainer = styled.div`
  margin: 0 4px;
  height: ${frameHeight.header};
  padding: ${getSpacing(2)} 0;
`;

export const HeaderBrandImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;
