import { getSizeInPx } from 'stylesheet';

import { SvgIcon } from './SvgIcon';
import { IconProps } from './types';

export const GDPRIcon = ({ widthInPx, heightInPx, color }: IconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 28 34"
    style={{
      width: getSizeInPx(widthInPx ?? 28),
      height: getSizeInPx(heightInPx ?? 34),
    }}
  >
    <path
      d="M26.7166 3.08335C26.523 2.92672 26.2967 2.81547 26.0545 2.75779C25.8122 2.7001 25.5601 2.69745 25.3166 2.75002C23.5357 3.12326 21.6972 3.12801 19.9144 2.76399C18.1315 2.39997 16.442 1.67487 14.95 0.633349C14.671 0.439803 14.3395 0.33609 14 0.33609C13.6604 0.33609 13.329 0.439803 13.05 0.633349C11.5579 1.67487 9.86843 2.39997 8.08558 2.76399C6.30273 3.12801 4.46424 3.12326 2.6833 2.75002C2.43987 2.69745 2.18774 2.7001 1.94547 2.75779C1.7032 2.81547 1.47693 2.92672 1.2833 3.08335C1.08993 3.24021 0.93417 3.43841 0.827456 3.66338C0.720742 3.88834 0.665789 4.13436 0.666636 4.38335V16.8C0.665154 19.1896 1.23459 21.545 2.32753 23.6699C3.42046 25.7949 5.00527 27.6281 6.94997 29.0167L13.0333 33.35C13.3156 33.551 13.6535 33.659 14 33.659C14.3465 33.659 14.6844 33.551 14.9666 33.35L21.05 29.0167C22.9947 27.6281 24.5795 25.7949 25.6724 23.6699C26.7653 21.545 27.3348 19.1896 27.3333 16.8V4.38335C27.3341 4.13436 27.2792 3.88834 27.1725 3.66338C27.0658 3.43841 26.91 3.24021 26.7166 3.08335ZM24 16.8C24.0012 18.6579 23.5588 20.4893 22.7094 22.1417C21.86 23.7941 20.6282 25.2198 19.1166 26.3L14 29.95L8.8833 26.3C7.3717 25.2198 6.13994 23.7941 5.29055 22.1417C4.44116 20.4893 3.99869 18.6579 3.99997 16.8V6.30002C7.49402 6.59907 10.9934 5.78839 14 3.98335C17.0066 5.78839 20.5059 6.59907 24 6.30002V16.8ZM16.5666 12.9833L12.0833 17.4833L10.6 15.9833C10.2861 15.6695 9.86047 15.4932 9.41663 15.4932C8.9728 15.4932 8.54714 15.6695 8.2333 15.9833C7.91946 16.2972 7.74315 16.7228 7.74315 17.1667C7.74315 17.6105 7.91946 18.0362 8.2333 18.35L10.9 21.0167C11.0549 21.1729 11.2392 21.2969 11.4423 21.3815C11.6454 21.4661 11.8633 21.5097 12.0833 21.5097C12.3033 21.5097 12.5212 21.4661 12.7243 21.3815C12.9274 21.2969 13.1117 21.1729 13.2666 21.0167L19 15.3333C19.3138 15.0195 19.4901 14.5939 19.4901 14.15C19.4901 13.7062 19.3138 13.2805 19 12.9667C18.6861 12.6528 18.2605 12.4765 17.8166 12.4765C17.3728 12.4765 16.9471 12.6528 16.6333 12.9667L16.5666 12.9833Z"
      fill={color ?? 'currentColor'}
    />
  </SvgIcon>
);
