import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@material-ui/core';
import { Loader } from 'components/Loader/Loader';
import { ReactNode } from 'react';
import { frameHeight } from 'stylesheet';

import { useButtonStyle } from './Button.style';

export type ButtonDisplayStyle =
  | 'filledOrange'
  | 'unfilledOrange'
  | 'unfilledGrey'
  | 'filledRed'
  | 'unfilledWhite';

export type AdditionalButtonProps = {
  displayStyle: ButtonDisplayStyle;
  margins?: string;
  bigPaddings?: boolean;
  isLoading?: boolean;
  children?: ReactNode;
  justifyContentLeft?: boolean;
  widthInPx?: number;
  allSidePadding?: string;
};
type ButtonProps = MuiButtonProps & AdditionalButtonProps;

export const Button = ({
  displayStyle,
  margins,
  bigPaddings,
  startIcon,
  href,
  disabled,
  onClick,
  onMouseEnter,
  onMouseLeave,
  type,
  isLoading,
  children,
  justifyContentLeft,
  widthInPx,
  allSidePadding,
}: ButtonProps) => {
  const classes = useButtonStyle({
    margins,
    bigPaddings,
    displayStyle,
    children,
    isLoading,
    justifyContentLeft,
    widthInPx,
    allSidePadding,
  })();
  return (
    <MuiButton
      classes={classes}
      disabled={disabled}
      href={href}
      onClick={isLoading ? () => null : onClick}
      onMouseEnter={onMouseEnter ? onMouseEnter : () => null}
      onMouseLeave={onMouseLeave ? onMouseLeave : () => null}
      startIcon={startIcon}
      type={type}
      disableFocusRipple
    >
      {children}
      {isLoading && (
        <Loader sizeInPx={frameHeight.buttonInnerSize} color="inherit" position="absolute" />
      )}
    </MuiButton>
  );
};
