import { Drawer } from '@material-ui/core';
import { CloseIcon } from 'assets/icons/CloseIcon';
import { IconButton } from 'components/IconButton/IconButton';
import { ReactNode } from 'react';

import {
  SidePanelHeader,
  TitleIconAndTitleContainer,
  TitleIconContainer,
  useDrawerStyle,
} from './SidePanel.style';

type SidePanelProps = {
  title: string;
  titleIcon: JSX.Element;
  isOpen: boolean;
  onClose?: () => void;
  children: ReactNode;
  widthInPercent?: number;
};
export const SidePanel = ({
  title,
  titleIcon,
  isOpen,
  onClose,
  children,
  widthInPercent,
}: SidePanelProps): JSX.Element => {
  const classes = useDrawerStyle({ widthInPercent })();
  return (
    <Drawer classes={classes} open={isOpen} anchor="right" onClose={onClose}>
      <SidePanelHeader>
        <TitleIconAndTitleContainer>
          <TitleIconContainer>{titleIcon}</TitleIconContainer>
          {title}
        </TitleIconAndTitleContainer>

        {onClose && (
          <IconButton size="medium" displayStyle="grey" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </SidePanelHeader>
      {children}
    </Drawer>
  );
};
