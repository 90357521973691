import { lazy } from 'react';

export const HomePage = lazy(() =>
  import('./Home/HomePage').then((module) => ({
    default: module.HomePage,
  })),
);

export const HistoryPage = lazy(() =>
  import('./History/HistoryPage').then((module) => ({
    default: module.HistoryPage,
  })),
);

export const ClientCompaniesPage = lazy(() =>
  import('./ClientCompanies/ClientCompaniesPage').then((module) => ({
    default: module.ClientCompaniesPage,
  })),
);

export const ClientCompanyMainPage = lazy(() =>
  import('./ClientCompany/ClientCompanyMainPage').then((module) => ({
    default: module.ClientCompanyMainPage,
  })),
);

export const ClientCompanyHistoryPage = lazy(() =>
  import('./ClientCompany/ClientCompanyHistoryPage').then((module) => ({
    default: module.ClientCompanyHistoryPage,
  })),
);

export const UsersPage = lazy(() =>
  import('./Users/UsersPage').then((module) => ({
    default: module.UsersPage,
  })),
);

export const ClientCompanyUsersPage = lazy(() =>
  import('./ClientCompany/ClientCompanyUsersPage').then((module) => ({
    default: module.ClientCompanyUsersPage,
  })),
);

export const ClientCompanyBusinessReferentsPage = lazy(() =>
  import('./ClientCompany/ClientCompanyBusinessReferentsPage').then((module) => ({
    default: module.ClientCompanyBusinessReferentsPage,
  })),
);

export const SitesAndDocumentsPage = lazy(() =>
  import('./Sites/SitesAndDocumentsPage').then((module) => ({
    default: module.SitesAndDocumentsPage,
  })),
);

export const ClientRequestsPage = lazy(() =>
  import('./ClientRequests/ClientRequestsPage').then((module) => ({
    default: module.ClientRequestsPage,
  })),
);

export const InvitationProcessEndPage = lazy(() =>
  import('./InvitationProcessEnd/InvitationProcessEndPage').then((module) => ({
    default: module.InvitationProcessEndPage,
  })),
);

export const SubscriptionRequestPage = lazy(() =>
  import('./SubscriptionRequest/SubscriptionRequestPage').then((module) => ({
    default: module.SubscriptionRequestPage,
  })),
);

export const SubscriptionRequestSuccessPage = lazy(() =>
  import('./SubscriptionRequest/SubscriptionRequestSuccessPage').then((module) => ({
    default: module.SubscriptionRequestSuccessPage,
  })),
);

export const BusinessAdminClientRequestsPage = lazy(() =>
  import('./ClientRequests/BusinessAdminClientRequests/BusinessAdminClientRequestsPage').then(
    (module) => ({
      default: module.BusinessAdminClientRequestsPage,
    }),
  ),
);

export const BusinessReferentsPage = lazy(() =>
  import('./BusinessReferents/BusinessReferentsPage').then((module) => ({
    default: module.BusinessReferentsPage,
  })),
);
