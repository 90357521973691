import { DialogContent, makeStyles, withStyles } from '@material-ui/core';
import styled from 'styled-components';
import { colorPalette, getSpacing, typography } from 'stylesheet';

import { DialogStyleProps } from './Dialog';

export const useDialogStyle = ({ widthInPercent, heightInPercent }: DialogStyleProps) =>
  makeStyles({
    paper: {
      padding: getSpacing(3),
      ...(widthInPercent
        ? { width: `${widthInPercent}%`, maxWidth: `${widthInPercent}%` }
        : { minWidth: '400px', maxWidth: '800px' }),
      ...(heightInPercent
        ? { height: `${heightInPercent}%` }
        : { minHeight: '200px', maxHeight: '90%' }),
      borderRadius: getSpacing(2),
    },
  });

export const StyledDialogContent = withStyles({
  root: {
    padding: 0,
  },
})(DialogContent);

export const StyledDialogHeader = styled.div`
  height: ${getSpacing(8)};
  margin-bottom: ${getSpacing(3)};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleContainer = styled.div<{ showCloseIcon: boolean }>`
  width: 100%;
  display: flex;
  justify-content: ${(props) => (props.showCloseIcon ? 'start' : 'center')};
  ${typography.title_xsmall};
`;

const getTitleIconColor = (titleIconColor?: string) => {
  switch (titleIconColor) {
    case 'grey':
      return colorPalette.greyMain;
    case 'orange':
      return colorPalette.secondary_600;
  }
};

export const TitleIconContainer = styled.div<{ titleIconColor?: string }>`
  width: ${getSpacing(5)};
  height: ${getSpacing(5)};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => getTitleIconColor(props.titleIconColor)};
  margin-right: ${getSpacing(2)};
`;
