import { ApiPermissionInList } from 'services/api/permissions/types';

export const byIdMdm = (permission1: ApiPermissionInList, permission2: ApiPermissionInList) => {
  if (permission1.clientCompany === undefined || permission2.clientCompany === undefined) {
    return 0;
  }
  return permission1.clientCompany?.id - permission2.clientCompany?.id;
};

export const convertPermissionToSelectInputOption = (permission: ApiPermissionInList) => ({
  label: permission.clientCompany
    ? `${permission.clientCompany.name} - ${permission.clientCompany.id}`
    : '',
  value: permission.id,
});

export const findSelectedPermission = (
  permissions: ApiPermissionInList[],
  searchedPermissionId: string | number | null,
): ApiPermissionInList | null => {
  if (permissions.length > 0) {
    const selectedPermission = permissions.find(
      (permission) => permission.id.toString() === searchedPermissionId,
    );
    return selectedPermission || permissions[0];
  }
  return null;
};
