import { SelectClientCompanyIcon } from 'assets/icons/SelectClientCompanyIcon';
import { SelectInput } from 'components/Inputs/SelectInput/SelectInput';
import { ChangeEvent, FunctionComponent, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAppDispatch, usePersistReduxStoreAndReloadPage } from 'redux/hooks';
import { getSelectedPermission, getUserPermissions, isBackOfficeUser } from 'redux/User/selectors';
import { updateSelectedPermissionId } from 'redux/User/slice';
import { ROUTES } from 'routing/routes';
import { useUserInformation } from 'services/authentication/useUserInformation';
import { convertPermissionToSelectInputOption } from 'services/utils/permissions';

import {
  LEGAl_CORPORATION_BRAND_LOGOS_BY_ID,
  PLATFORM_LEGAL_CORPORATION_ID,
} from '../../../services/api/constants';
import {
  HeaderBrandImage,
  HeaderBrandImageContainer,
  HeaderContainer,
  LogoContainer,
} from './Header.style';
import { UserCard } from './UserCard/UserCard';

const HEADER_BRAND_LOGO_FILENAME = 'HeaderBrandLogo.png';

export const Header: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const persistReduxStoreAndReloadPage = usePersistReduxStoreAndReloadPage();
  const userInformation = useUserInformation();
  const userPermissions = useSelector(getUserPermissions);
  const userSelectedPermission = useSelector(getSelectedPermission);
  const isBackofficeUser = useSelector(isBackOfficeUser);
  const displayCompanySelectInput = userInformation.isAuthenticated && !isBackofficeUser;
  const SELECT_INPUT_ID = 'userPermissionsSelectInput';
  const legalCorporationID = PLATFORM_LEGAL_CORPORATION_ID;

  const userPermissionsForSelectInput = useMemo(() => {
    return userPermissions.map(convertPermissionToSelectInputOption);
  }, [userPermissions]);

  const onSelectInputChange = async (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    dispatch(updateSelectedPermissionId(event.target.value));
    persistReduxStoreAndReloadPage();
  };
  return (
    <HeaderContainer>
      <LogoContainer>
        {legalCorporationID && LEGAl_CORPORATION_BRAND_LOGOS_BY_ID[legalCorporationID] ? (
          LEGAl_CORPORATION_BRAND_LOGOS_BY_ID[legalCorporationID].map((logo: any, index: any) => (
            <a href={logo.url} key={logo.image} target="_blank" rel="noreferrer">
              <HeaderBrandImageContainer>
                <HeaderBrandImage src={`${process.env.PUBLIC_URL}/config/${logo.image}`} />
              </HeaderBrandImageContainer>
            </a>
          ))
        ) : (
          <Link to={ROUTES.HOME}>
            <HeaderBrandImageContainer>
              <HeaderBrandImage
                src={`${process.env.PUBLIC_URL}/config/${HEADER_BRAND_LOGO_FILENAME}`}
              />
            </HeaderBrandImageContainer>
          </Link>
        )}
      </LogoContainer>
      {displayCompanySelectInput && (
        <SelectInput
          id={SELECT_INPUT_ID}
          name={SELECT_INPUT_ID}
          value={userSelectedPermission?.id}
          onChange={onSelectInputChange}
          choices={userPermissionsForSelectInput}
          startIcon={<SelectClientCompanyIcon />}
          preserveSpacingForHelperText={false}
        />
      )}
      {userInformation.isAuthenticated && <UserCard user={userInformation} />}
    </HeaderContainer>
  );
};
