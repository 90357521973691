import { getSizeInPx } from 'stylesheet';

import { SvgIcon } from './SvgIcon';
import { IconProps } from './types';

export const HistoryIcon = ({ widthInPx, heightInPx, color }: IconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 21 20"
    style={{
      width: getSizeInPx(widthInPx ?? 21),
      height: getSizeInPx(heightInPx ?? 20),
    }}
  >
    <path
      d="M10.5 0C7.93639 0.00731219 5.47349 0.998914 3.62 2.77V1C3.62 0.734784 3.51464 0.48043 3.32711 0.292893C3.13957 0.105357 2.88522 0 2.62 0C2.35478 0 2.10043 0.105357 1.91289 0.292893C1.72536 0.48043 1.62 0.734784 1.62 1V5.5C1.62 5.76522 1.72536 6.01957 1.91289 6.20711C2.10043 6.39464 2.35478 6.5 2.62 6.5H7.12C7.38522 6.5 7.63957 6.39464 7.82711 6.20711C8.01464 6.01957 8.12 5.76522 8.12 5.5C8.12 5.23478 8.01464 4.98043 7.82711 4.79289C7.63957 4.60536 7.38522 4.5 7.12 4.5H4.72C6.00578 3.15636 7.71951 2.30265 9.56653 2.08567C11.4136 1.86868 13.2785 2.30198 14.8407 3.31104C16.4028 4.32011 17.5646 5.84191 18.1263 7.61479C18.688 9.38767 18.6145 11.3009 17.9184 13.0254C17.2223 14.7499 15.9472 16.1781 14.3122 17.0643C12.6772 17.9505 10.7845 18.2394 8.95956 17.8813C7.13463 17.5232 5.49147 16.5405 4.31259 15.1022C3.13372 13.6638 2.49279 11.8597 2.5 10C2.5 9.73478 2.39464 9.48043 2.20711 9.29289C2.01957 9.10536 1.76522 9 1.5 9C1.23478 9 0.98043 9.10536 0.792893 9.29289C0.605357 9.48043 0.5 9.73478 0.5 10C0.5 11.9778 1.08649 13.9112 2.1853 15.5557C3.28412 17.2002 4.8459 18.4819 6.67317 19.2388C8.50043 19.9957 10.5111 20.1937 12.4509 19.8079C14.3907 19.422 16.1725 18.4696 17.5711 17.0711C18.9696 15.6725 19.922 13.8907 20.3079 11.9509C20.6937 10.0111 20.4957 8.00043 19.7388 6.17317C18.9819 4.3459 17.7002 2.78412 16.0557 1.6853C14.4112 0.58649 12.4778 0 10.5 0ZM10.5 6C10.2348 6 9.98043 6.10536 9.79289 6.29289C9.60536 6.48043 9.5 6.73478 9.5 7V10C9.5 10.2652 9.60536 10.5196 9.79289 10.7071C9.98043 10.8946 10.2348 11 10.5 11H12.5C12.7652 11 13.0196 10.8946 13.2071 10.7071C13.3946 10.5196 13.5 10.2652 13.5 10C13.5 9.73478 13.3946 9.48043 13.2071 9.29289C13.0196 9.10536 12.7652 9 12.5 9H11.5V7C11.5 6.73478 11.3946 6.48043 11.2071 6.29289C11.0196 6.10536 10.7652 6 10.5 6Z"
      fill={color ?? 'currentColor'}
    />
  </SvgIcon>
);
