import { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { PLATFORM_LEGAL_CORPORATION_LABEL } from 'services/api/constants';
import { useIntlByKey } from 'services/i18n/hooks/useIntlByKey';

export const AppHelmet: FunctionComponent = () => {
  const appHelmetIntl = useIntlByKey('app.helmet');

  return (
    <Helmet>
      <title>
        {appHelmetIntl('title', { legalCorporationLabel: PLATFORM_LEGAL_CORPORATION_LABEL })}
      </title>
      <meta
        name="description"
        content={appHelmetIntl('description', {
          legalCorporationLabel: PLATFORM_LEGAL_CORPORATION_LABEL,
        })}
      />
    </Helmet>
  );
};
