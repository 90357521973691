import { TableFilters } from '../filtersKeys';

export enum UserClientCompaniesFiltersKeys {
  TYPE = 'clientCompany.isInternal',
  ID_MDM = 'clientCompany.id',
  CLIENT_COMPANY_NAME = 'clientCompany.name',
  SIRET = 'clientCompany.siret',
}

export const USER_CLIENT_COMPANIES_FILTERS_INITIAL_VALUES: TableFilters = {
  [UserClientCompaniesFiltersKeys.TYPE]: '',
  [UserClientCompaniesFiltersKeys.ID_MDM]: '',
  [UserClientCompaniesFiltersKeys.CLIENT_COMPANY_NAME]: '',
  [UserClientCompaniesFiltersKeys.SIRET]: '',
};
