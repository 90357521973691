import { getSizeInPx } from 'stylesheet';

import { SvgIcon } from './SvgIcon';
import { IconWithArrowDirectionProps } from './types';

export const ArrowIcon = ({
  color,
  widthInPx,
  heightInPx,
  arrowDirection = 'down',
}: IconWithArrowDirectionProps): JSX.Element => {
  const getRotation = () => {
    switch (arrowDirection) {
      case 'left':
        return '0.25';
      case 'right':
        return '-0.25';
      case 'top':
        return '0.5';
      case 'down':
        return '0';
    }
  };
  return (
    <SvgIcon
      viewBox="0 0 18 12"
      style={{
        width: getSizeInPx(widthInPx ?? 18),
        height: getSizeInPx(heightInPx ?? 12),
        transform: `rotate(${getRotation()}turn)`,
      }}
    >
      <path
        d="M17.8378 1.7027C18.0541 1.94595 18.0541 2.17568 17.8378 2.39189L9.32432 10.9054C9.10811 11.1216 8.89189 11.1216 8.67568 10.9054L0.162162 2.39189C-0.0540541 2.17568 -0.0540541 1.94595 0.162162 1.7027L0.972973 0.932432C1.18919 0.689189 1.41892 0.689189 1.66216 0.932432L9 8.22973L16.3378 0.932432C16.5811 0.689189 16.8108 0.689189 17.027 0.932432L17.8378 1.7027Z"
        fill={color ?? 'currentColor'}
      />
    </SvgIcon>
  );
};
