import styled from 'styled-components';
import { colorPalette, getSpacing } from 'stylesheet';

export const MessageContainer = styled.div``;

export const MessagePart = styled.div`
  text-align: justify;
  margin-top: ${getSpacing(2)};
`;

export const LinkPart = styled.div`
  text-align: center;
  margin-top: ${getSpacing(2)};
`;

export const Signature = styled.div`
  margin-top: ${getSpacing(4)};
  color: ${colorPalette.primary};
  font-weight: bold;
  text-align: right;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const GDPRSidePanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: ${getSpacing(4)};
`;
