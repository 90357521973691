import emptySearchApiResponse from 'assets/images/EmptySearchApiResponse.png';

import {
  EmptySearchApiResponseContainer,
  EmptySearchApiResponseImage,
  EmptySearchApiResponseTitle,
} from './EmptySearchApiResponse.style';

type Props = {
  title?: string;
};

export const EmptySearchApiResponse = ({ title }: Props): JSX.Element => {
  return (
    <EmptySearchApiResponseContainer>
      <EmptySearchApiResponseImage src={emptySearchApiResponse} />
      {title && <EmptySearchApiResponseTitle>{title}</EmptySearchApiResponseTitle>}
    </EmptySearchApiResponseContainer>
  );
};
