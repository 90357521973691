import styled from 'styled-components';

import { lateralMenuStyle } from '../LateralMenu.style';

export const LateralMenuButtonContainer = styled.div`
  ${lateralMenuStyle.itemContainer};
`;

export const LateralMenuButtonLabel = styled.p`
  ${lateralMenuStyle.itemLabel}
`;
