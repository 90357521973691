import { Component, ComponentType, ErrorInfo, ReactNode } from 'react';
import { logger } from 'services/logs/logger';

// import { captureException } from '../services/sentry';
import { FallbackProps } from '../AppCrashFallback/AppCrashFallback';

export interface ErrorBoundaryProps {
  FallbackComponent: ComponentType<FallbackProps>;
  hasError?: boolean;
  eventId?: string;
}
interface ErrorBoundaryState {
  hasError: boolean;
  eventId: string;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state = {
    hasError: false,
    eventId: '',
  };

  static getDerivedStateFromProps(
    props: ErrorBoundaryProps,
    state: ErrorBoundaryState,
  ): ErrorBoundaryState {
    // Handles case where hasError is passed in as a prop too
    const { hasError = false } = props;

    return {
      hasError: hasError || state.hasError || false,
      eventId: props.eventId ?? state.eventId,
    };
  }

  componentDidCatch(error: any, errorInfo: ErrorInfo): void {
    // const eventId = captureException(error, { errorInfo });
    const eventId = '';
    this.setState({ eventId, hasError: true });
    logger.logError('Error on render', error);
  }

  componentDidMount() {
    // Handle unhandled Promise rejections globally

    window.addEventListener('unhandledrejection', this.handlePromiseRejection);
  }

  componentWillUnmount() {
    // Remove the event listener when the component unmounts

    window.removeEventListener('unhandledrejection', this.handlePromiseRejection);
  }

  handlePromiseRejection = (event: any) => {
    const { reason } = event;
    logger.logError('Error on promise handle rejection', reason);
  };

  render(): ReactNode {
    const { hasError, eventId } = this.state;
    const { FallbackComponent, children } = this.props;

    return hasError ? <FallbackComponent eventId={eventId} /> : children;
  }
}
