import {
  DateFilter,
  Filter,
  RangeFilter,
  TableFilters,
  TextFilter,
} from 'redux/Tables/filtersKeys';

export const filtersToQueryParams = (filters: TableFilters) => {
  let queryParams = '';

  for (const [filter, value] of Object.entries(filters)) {
    if (isDateFilter(value)) {
      queryParams += getDateFilterQueryParam(filter, value);
    }

    if (isRangeFilter(value)) {
      queryParams += getRangeFilterQueryParam(filter, value);
    }

    if (isTextFilter(value)) {
      if (value !== '') {
        queryParams += `&${filter}=${value}`;
      }
    }
  }
  return queryParams;
};

export const hasFiltersAtLeastOneNonEmptyValue = (filters: TableFilters) => {
  for (const value of Object.values(filters)) {
    if (isFilterEmpty(value)) {
      return true;
    }
  }

  return false;
};

const isDateFilter = (value: Filter): value is DateFilter => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (value as any).before !== undefined;
};

const isRangeFilter = (value: Filter): value is RangeFilter => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (value as any).min !== undefined;
};

// const isBooleanFilter

const isTextFilter = (value: Filter): value is TextFilter => {
  return typeof value === 'string';
};

export const isFilterEmpty = (filter: Filter) => {
  if (isDateFilter(filter) && !isDateFilterEmpty(filter)) {
    return true;
  }

  if (isRangeFilter(filter) && !isRangeFilterEmpty(filter)) {
    return true;
  }

  if (isTextFilter(filter) && filter !== '') {
    return true;
  }
};

const getDateFilterQueryParam = (filterKey: string, dateFilter: DateFilter) => {
  let dateFilterQueryParam = '';
  if (dateFilter.before !== null) {
    dateFilterQueryParam += `&${filterKey}[before]=${dateFilter.before} 23:59:59`;
  }
  if (dateFilter.after !== null) {
    dateFilterQueryParam += `&${filterKey}[after]=${dateFilter.after} 00:00:00`;
  }

  return dateFilterQueryParam;
};

const getRangeFilterQueryParam = (filterKey: string, rangeFilter: RangeFilter) => {
  let rangeFilterQueryParam = '';
  if (rangeFilter.min !== null) {
    rangeFilterQueryParam += `&${filterKey}[gte]=${rangeFilter.min}`;
  }
  if (rangeFilter.max !== null) {
    rangeFilterQueryParam += `&${filterKey}[lte]=${rangeFilter.max}`;
  }

  return rangeFilterQueryParam;
};

const isDateFilterEmpty = (dateFilter: DateFilter) => {
  return dateFilter.before === null && dateFilter.after === null;
};

const isRangeFilterEmpty = (rangeFilter: RangeFilter) => {
  return rangeFilter.min === null && rangeFilter.max === null;
};
