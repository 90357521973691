import { UploadedFileInformation } from 'services/utils/formik/types';
import { UPLOADED_FILES_FORMIK_KEY } from 'services/utils/formik/utils';

import { ClientAuthorizationRequestBody } from '../authorization/types';
import { BaseNestedApiClientCompany } from '../clientCompanies/types';
import { ApiClientRequestDocument } from '../ClientRequestDocuments/types';
import { ApiPermissionForUserInBusinessAdminClientRequestList } from '../permissions/types';

export type ClientRequestFileInformation = {
  file: File;
  originalFileNameWithExtension: string;
  customFileNameWithoutExtension: string;
  fileType: number;
};

export type CreateClientRequestForm = {
  recipientId: number;
  requestTitle: string;
  requestDetails: string;
  [UPLOADED_FILES_FORMIK_KEY]: UploadedFileInformation[];
};

export type CreateClientRequestMessageForm = {
  requestMessage: string;
  [UPLOADED_FILES_FORMIK_KEY]: UploadedFileInformation[];
};

export type ClientRequestDocument = {
  documentPath: string;
  description: string;
  typeId?: number;
};

type ClientRequestMessage = { message: string; documentsInformation: ClientRequestDocument[] };

export type CreateClientRequestBody = ClientAuthorizationRequestBody & {
  title: string;
  recipientId: number;
  clientRequestMessage: ClientRequestMessage;
  isBackofficeIssuer: boolean;
};

export type CreateClientRequestMessageBody = ClientAuthorizationRequestBody & {
  clientRequestId: number;
  clientRequestMessage: ClientRequestMessage;
};

export type ApiClientRequestInList = {
  _id: number;
  reference: string;
  title: string;
  author: ApiPermissionForUserInBusinessAdminClientRequestList;
  recipient: ApiPermissionForUserInBusinessAdminClientRequestList;
  authorFirstName: string;
  authorLastName: string;
  isBackOfficeIssuer: boolean;
  createdAt: string;
  updatedAt: string;
  shouldRead: boolean;
};

export enum ClientRequestTableRowKeys {
  ID = 'id',
  REFERENCE = 'reference',
  TITLE = 'title',
  AUTHOR_FULL_NAME = 'authorFullName',
  RECIPIENT_FULL_NAME = 'recipientFullName',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  CLIENT_REQUEST_SOURCE = 'clientRequestSource',
  IS_OPEN_CLIENT_REQUEST_BUTTON_AVAILABLE = 'isOpenClientRequestButtonAvailable',
}

export type ClientRequestForTable = {
  values: {
    [ClientRequestTableRowKeys.ID]: number;
    [ClientRequestTableRowKeys.REFERENCE]: string;
    [ClientRequestTableRowKeys.TITLE]: string;
    [ClientRequestTableRowKeys.AUTHOR_FULL_NAME]: string;
    [ClientRequestTableRowKeys.RECIPIENT_FULL_NAME]: string;
    [ClientRequestTableRowKeys.CREATED_AT]: string;
    [ClientRequestTableRowKeys.UPDATED_AT]: string;
    [ClientRequestTableRowKeys.IS_OPEN_CLIENT_REQUEST_BUTTON_AVAILABLE]: boolean;
  };
  isHighlighted: boolean;
};

type ApiClientRequestMessage = {
  id: number;
  content: string;
  authorId: string;
  authorFirstName: string;
  authorLastName: string;
  clientRequestDocuments: ApiClientRequestDocument[];
  createdAt: string;
  updatedAt: string;
};

export type ApiClientRequestInDetails = {
  id: number;
  reference: string;
  title: string;
  clientRequestMessages: ApiClientRequestMessage[];
  createdAt: string;
  updatedAt: string;
  clientCompany: Pick<BaseNestedApiClientCompany, 'id' | 'name'>;
};

export type ClientRequestMessageForSidePanel = {
  id: number;
  content: string;
  authorId: string;
  authorFullName: string;
  documentsNumber: number;
  createdAt: string;
  updatedAt: string;
};

export enum SpecificClientRequestDocumentTableRowKeys {
  ID = 'id',
  TYPE = 'type',
  DESCRIPTION = 'description',
  AUTHOR_FULL_NAME = 'authorFullName',
  CREATED_AT = 'createdAt',
  PREVIEW_DOCUMENT_URL = 'previewDocumentUrl',
  DOWNLOAD_DOCUMENT_URL = 'downloadDocumentUrl',
}

export type ClientRequestDocumentForSidePanel = {
  values: {
    [SpecificClientRequestDocumentTableRowKeys.ID]: number;
    [SpecificClientRequestDocumentTableRowKeys.TYPE]?: string;
    [SpecificClientRequestDocumentTableRowKeys.DESCRIPTION]: string;
    [SpecificClientRequestDocumentTableRowKeys.AUTHOR_FULL_NAME]: string;
    [SpecificClientRequestDocumentTableRowKeys.CREATED_AT]: string;
    [SpecificClientRequestDocumentTableRowKeys.PREVIEW_DOCUMENT_URL]: string;
    [SpecificClientRequestDocumentTableRowKeys.DOWNLOAD_DOCUMENT_URL]: string;
  };
};

export type ClientRequestForSidePanel = {
  id: number;
  reference: string;
  title: string;
  messages: ClientRequestMessageForSidePanel[];
  documents: ClientRequestDocumentForSidePanel[];
  clientCompany: Pick<BaseNestedApiClientCompany, 'id' | 'name'>;
};

export type ApiCountClientRequestUnread = {
  countUnreadClientRequestViews: number;
};
