export const getClientCompanySingleLegalCorporationId = (legalCorporationId: string) => {
  const legalCorporationIds = legalCorporationId.split(',');
  return legalCorporationIds.sort()[0];
};

export const invoiceDateParam = 'invDt';
export const invoiceIdParam = 'invoiceId';

export const getPermissionId = () => {
  return getParameter('pId');
};

export const getInvoiceId = () => {
  return getParameter(invoiceIdParam);
};

export const getInvoiceUpdatedDate = () => {
  return getParameter(invoiceDateParam);
};

const getParameter = (param: string) => {
  return new URLSearchParams(window.location.search).get(param) || '';
};
