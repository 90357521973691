import { getSizeInPx } from 'stylesheet';

import { SvgIcon } from './SvgIcon';
import { IconProps } from './types';

export const LogOutIcon = ({ widthInPx, heightInPx, color }: IconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 16 18"
    style={{
      width: getSizeInPx(widthInPx ?? 16),
      height: getSizeInPx(heightInPx ?? 18),
    }}
  >
    <path
      d="M8.99998 1.00869C9.00019 0.456408 8.55265 0.00851718 8.00037 0.00830086C7.44808 0.00808491 7.00019 0.455625 6.99997 1.00791L6.99684 9.01136C6.99663 9.56364 7.44417 10.0115 7.99645 10.0117C8.54874 10.012 8.99663 9.56443 8.99684 9.01214L8.99998 1.00869Z"
      fill={color ?? 'currentColor'}
    />
    <path
      d="M0 9.99143C0 7.78233 0.895405 5.78235 2.34308 4.33464L3.7573 5.74885C2.67155 6.83464 2 8.33461 2 9.99143C2 13.3051 4.68629 15.9914 8 15.9914C11.3137 15.9914 14 13.3051 14 9.99143C14 8.33457 13.3284 6.83457 12.2426 5.74878L13.6568 4.33457C15.1046 5.78229 16 7.78229 16 9.99143C16 14.4097 12.4183 17.9914 8 17.9914C3.58172 17.9914 0 14.4097 0 9.99143Z"
      fill={color ?? 'currentColor'}
    />
  </SvgIcon>
);
