import { nanoid } from '@reduxjs/toolkit';
import { PLATFORM_LEGAL_CORPORATION_ID } from 'services/api/constants';
import { getUploadPresignedUrl, uploadFileToAzureStorage } from 'services/api/documents/client';
import {
  APP_DOCUMENTS_FOLDER_NAME,
  APP_DOCUMENTS_SUBFOLDER_NAME,
} from 'services/api/documents/types';
import * as yup from 'yup';

import { formatCurrentDateForFileName } from '../formatDate';
import { UploadedFileInformation } from '../formik/types';

export const UPLOADED_FILES_FORMIK_KEY = 'uploadedFilesInformation';
export const DESCRIPTION_ATTRIBUTE_FILE_FORMIK_KEY = 'description';
export const TYPE_ID_ATTRIBUTE_FILE_FORMIK_KEY = 'fileTypeId';
export const FILE_ATTRIBUTE_FILE_FORMIK_KEY = 'file';
export const DOCUMENT_PATH_FILE_FORMIK_KEY = 'documentPath';

export const DESCRIPTION_ATTRIBUTE_FILE_FORMIK_MAX_SIZE = 60;

export const getUploadedFilesValidationSchema = (requiredFieldMessage: string) =>
  yup.object().shape({
    [UPLOADED_FILES_FORMIK_KEY]: yup.array().of(
      yup.object().shape({
        [FILE_ATTRIBUTE_FILE_FORMIK_KEY]: yup.mixed().required(requiredFieldMessage),
        [DESCRIPTION_ATTRIBUTE_FILE_FORMIK_KEY]: yup.string().required(requiredFieldMessage),
        [DOCUMENT_PATH_FILE_FORMIK_KEY]: yup.string().required(requiredFieldMessage),
      }),
    ),
  });

const uploadFormFile = async (
  fileInformation: UploadedFileInformation,
  clientCompanyId?: number,
) => {
  const presignedUrl = await getUploadPresignedUrl(
    fileInformation[DOCUMENT_PATH_FILE_FORMIK_KEY],
    clientCompanyId,
  );
  await uploadFileToAzureStorage(presignedUrl, fileInformation[FILE_ATTRIBUTE_FILE_FORMIK_KEY]);
};

export const uploadFormFiles = (
  filesInformation: UploadedFileInformation[],
  clientCompanyId?: number,
) =>
  Promise.all(
    filesInformation.map((fileInformation) => uploadFormFile(fileInformation, clientCompanyId)),
  );

export const computeClientRequestDocumentPath = (filename: string, clientCompanyId: number) => {
  return `/${[
    APP_DOCUMENTS_FOLDER_NAME,
    APP_DOCUMENTS_SUBFOLDER_NAME.CLIENT_REQUEST,
    PLATFORM_LEGAL_CORPORATION_ID,
    clientCompanyId,
    formatCurrentDateForFileName(),
    nanoid(),
    filename,
  ].join('/')}`;
};

export const computeSiteDocumentDocumentPath = (filename: string) => {
  return `/${[
    APP_DOCUMENTS_FOLDER_NAME,
    APP_DOCUMENTS_SUBFOLDER_NAME.SITE_DOCUMENT,
    PLATFORM_LEGAL_CORPORATION_ID,
    formatCurrentDateForFileName(),
    nanoid(),
    filename,
  ].join('/')}`;
};

export const getComputeClientRequestDocumentPathForClientCompany = (clientCompanyId: number) => {
  return (filename: string) => computeClientRequestDocumentPath(filename, clientCompanyId);
};
