/* eslint-disable max-lines */
import { RootState } from 'redux/store';
import { isFilterEmpty } from 'services/utils/filtersToQueryParams';

import { FilterKey, TableFilters, TableSort } from './filtersKeys';
import { TableState } from './slice';

export enum TableKey {
  CLIENT_COMPANIES = 'clientCompanies',
  USER_CLIENT_COMPANIES = 'userClientCompanies',
  BUSINESS_ADMIN_CLIENT_REQUESTS = 'businessAdminClientRequests',
  CLIENT_REQUESTS = 'clientRequests',
  DELIVERY_NOTES = 'deliveryNotes',
  INVOICES = 'invoices',
  SITE_DOCUMENTS = 'siteDocuments',
  SPECIFIC_SITE_DOCUMENTS = 'specificSiteDocuments',
  SPECIFIC_CLIENT_REQUEST_DOCUMENTS = 'specificClientRequestDocuments',
  CLIENT_REQUEST_DOCUMENTS = 'clientRequestDocuments',
  CLIENT_COMPANY_USERS = 'clientCompanyUsers',
  CLIENT_COMPANY_BUSINESS_REFERENTS = 'clientCompanyBusinessReferents',
  INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE = 'inertWasteApplicationForPriorAcceptance',
}

export const getTable = (tableKey: TableKey) => {
  switch (tableKey) {
    case TableKey.DELIVERY_NOTES:
      return getDeliveryNotesTable;
    case TableKey.INVOICES:
      return getInvoicesTable;
    case TableKey.BUSINESS_ADMIN_CLIENT_REQUESTS:
      return getBusinessAdminClientRequestsTable;
    case TableKey.CLIENT_REQUESTS:
      return getClientRequestsTable;
    case TableKey.CLIENT_COMPANIES:
      return getClientCompaniesTable;
    case TableKey.USER_CLIENT_COMPANIES:
      return getUserClientCompaniesTable;
    case TableKey.CLIENT_COMPANY_BUSINESS_REFERENTS:
      return getClientCompanyBusinessReferentsTable;
    case TableKey.INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE:
      return getInertWasteApplicationForPriorAcceptanceTable;
    default:
      return () => ({
        selectAll: false,
        selectedIds: [],
        isReloading: false,
        filters: {} as Record<FilterKey, string>,
        sort: null,
      });
  }
};
export const getDeliveryNotesTable = (rootState: RootState): TableState =>
  rootState.tables[TableKey.DELIVERY_NOTES];
export const getInvoicesTable = (rootState: RootState): TableState =>
  rootState.tables[TableKey.INVOICES];
export const getClientCompaniesTable = (rootState: RootState): TableState =>
  rootState.tables[TableKey.CLIENT_COMPANIES];
export const getUserClientCompaniesTable = (rootState: RootState): TableState =>
  rootState.tables[TableKey.USER_CLIENT_COMPANIES];
export const getBusinessAdminClientRequestsTable = (rootState: RootState): TableState =>
  rootState.tables[TableKey.BUSINESS_ADMIN_CLIENT_REQUESTS];
export const getClientRequestsTable = (rootState: RootState): TableState =>
  rootState.tables[TableKey.CLIENT_REQUESTS];
export const getSiteDocumentsTable = (rootState: RootState): TableState =>
  rootState.tables[TableKey.SITE_DOCUMENTS];
export const getSpecificClientRequestDocumentsTable = (rootState: RootState): TableState =>
  rootState.tables[TableKey.SPECIFIC_CLIENT_REQUEST_DOCUMENTS];
export const getClientRequestDocumentsTable = (rootState: RootState): TableState =>
  rootState.tables[TableKey.CLIENT_REQUEST_DOCUMENTS];
export const getSpecificSiteDocumentsTable = (rootState: RootState): TableState =>
  rootState.tables[TableKey.SPECIFIC_CLIENT_REQUEST_DOCUMENTS];
export const getClientCompanyUsersTable = (rootState: RootState): TableState =>
  rootState.tables[TableKey.CLIENT_COMPANY_USERS];
export const getClientCompanyBusinessReferentsTable = (rootState: RootState): TableState =>
  rootState.tables[TableKey.CLIENT_COMPANY_BUSINESS_REFERENTS];
export const getInertWasteApplicationForPriorAcceptanceTable = (rootState: RootState): TableState =>
  rootState.tables[TableKey.INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE];

export const isTableSelected = (tableKey: TableKey) => {
  switch (tableKey) {
    case TableKey.DELIVERY_NOTES:
      return isDeliveryNotesTableSelected;
    case TableKey.INVOICES:
      return isInvoicesTableSelected;
    default:
      return () => false;
  }
};
export const isDeliveryNotesTableSelected = (rootState: RootState): boolean =>
  getDeliveryNotesTable(rootState).selectAll;

export const isInvoicesTableSelected = (rootState: RootState): boolean =>
  getInvoicesTable(rootState).selectAll;

export const isTableReloading = (tableKey: TableKey) => {
  switch (tableKey) {
    case TableKey.DELIVERY_NOTES:
      return isDeliveryNotesTableReloading;
    case TableKey.CLIENT_COMPANIES:
      return isClientCompaniesTableReloading;
    case TableKey.USER_CLIENT_COMPANIES:
      return isUserClientCompaniesTableReloading;
    case TableKey.BUSINESS_ADMIN_CLIENT_REQUESTS:
      return isBusinessAdminClientRequestsTableReloading;
    case TableKey.CLIENT_REQUESTS:
      return isClientRequestsTableReloading;
    case TableKey.SITE_DOCUMENTS:
      return isSiteDocumentsTableReloading;
    case TableKey.CLIENT_COMPANY_USERS:
      return isClientCompanyUsersTableReloading;
    case TableKey.INVOICES:
      return isInvoicesTableReloading;
    case TableKey.CLIENT_COMPANY_BUSINESS_REFERENTS:
      return isClientCompanyBusinessReferentsTableReloading;
    case TableKey.INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE:
      return isInertWasteApplicationForPriorAcceptanceTableReloading;
    default:
      return () => false;
  }
};
export const isDeliveryNotesTableReloading = (rootState: RootState): boolean =>
  getDeliveryNotesTable(rootState).isReloading;
export const isClientCompaniesTableReloading = (rootState: RootState): boolean =>
  getClientCompaniesTable(rootState).isReloading;
export const isUserClientCompaniesTableReloading = (rootState: RootState): boolean =>
  getUserClientCompaniesTable(rootState).isReloading;
export const isBusinessAdminClientRequestsTableReloading = (rootState: RootState): boolean =>
  getBusinessAdminClientRequestsTable(rootState).isReloading;
export const isClientRequestsTableReloading = (rootState: RootState): boolean =>
  getClientRequestsTable(rootState).isReloading;
export const isSiteDocumentsTableReloading = (rootState: RootState): boolean =>
  getSiteDocumentsTable(rootState).isReloading;
export const isClientCompanyUsersTableReloading = (rootState: RootState): boolean =>
  getClientCompanyUsersTable(rootState).isReloading;
export const isInvoicesTableReloading = (rootState: RootState): boolean =>
  getInvoicesTable(rootState).isReloading;
export const isClientCompanyBusinessReferentsTableReloading = (rootState: RootState): boolean =>
  getClientCompanyBusinessReferentsTable(rootState).isReloading;
export const isInertWasteApplicationForPriorAcceptanceTableReloading = (
  rootState: RootState,
): boolean => getInertWasteApplicationForPriorAcceptanceTable(rootState).isReloading;

const EMPTY_OBJECT_HOLDING_CONSTANT_REFERENCE = {};
export const getTableFilters = (tableKey: TableKey) => {
  switch (tableKey) {
    case TableKey.CLIENT_COMPANIES:
      return getClientCompaniesTableFilters;
    case TableKey.USER_CLIENT_COMPANIES:
      return getUserClientCompaniesTableFilters;
    case TableKey.CLIENT_REQUESTS:
      return getClientRequestsTableFilters;
    case TableKey.SITE_DOCUMENTS:
      return getSiteDocumentsTableFilters;
    case TableKey.DELIVERY_NOTES:
      return getDeliveryNotesTableFilters;
    case TableKey.SPECIFIC_SITE_DOCUMENTS:
      return getSpecificSiteDocumentsTableFilters;
    case TableKey.BUSINESS_ADMIN_CLIENT_REQUESTS:
      return getBusinessAdminClientRequestsTableFilters;
    case TableKey.INVOICES:
      return getInvoicesTableFilters;
    case TableKey.INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE:
      return getInertWasteApplicationForPriorAcceptanceTableFilters;
    default:
      return () => EMPTY_OBJECT_HOLDING_CONSTANT_REFERENCE as TableFilters;
  }
};

export const getClientCompaniesTableFilters = (rootState: RootState): TableFilters =>
  rootState.tables[TableKey.CLIENT_COMPANIES].filters;

export const getUserClientCompaniesTableFilters = (rootState: RootState): TableFilters =>
  rootState.tables[TableKey.USER_CLIENT_COMPANIES].filters;

export const getBusinessAdminClientRequestsTableFilters = (rootState: RootState): TableFilters =>
  rootState.tables[TableKey.BUSINESS_ADMIN_CLIENT_REQUESTS].filters;

export const getClientRequestsTableFilters = (rootState: RootState): TableFilters =>
  rootState.tables[TableKey.CLIENT_REQUESTS].filters;

export const getSiteDocumentsTableFilters = (rootState: RootState): TableFilters =>
  rootState.tables[TableKey.SITE_DOCUMENTS].filters;

export const getDeliveryNotesTableFilters = (rootState: RootState): TableFilters =>
  rootState.tables[TableKey.DELIVERY_NOTES].filters;

export const getInvoicesTableFilters = (rootState: RootState): TableFilters =>
  rootState.tables[TableKey.INVOICES].filters;

export const getSpecificSiteDocumentsTableFilters = (rootState: RootState): TableFilters =>
  rootState.tables[TableKey.SPECIFIC_SITE_DOCUMENTS].filters;

export const getInertWasteApplicationForPriorAcceptanceTableFilters = (
  rootState: RootState,
): TableFilters => rootState.tables[TableKey.INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE].filters;

export const getTableFiltersWithoutSearch = (filters: TableFilters) => {
  const { [FilterKey.SEARCH]: searchFilterValue, ...filtersValuesExceptSearch } = filters;

  return filtersValuesExceptSearch;
};

export const getNumberOfAppliedFilters = (filters: TableFilters) => {
  const filterValues = Object.values(filters);

  return filterValues.filter(isFilterEmpty).length;
};

export const getTableSort = (tableKey: TableKey) => {
  switch (tableKey) {
    case TableKey.DELIVERY_NOTES:
      return getDeliveryNotesTableSort;
    case TableKey.INVOICES:
      return getInvoicesTableSort;
    case TableKey.BUSINESS_ADMIN_CLIENT_REQUESTS:
      return getBusinessAdminClientRequestsTableSort;
    case TableKey.CLIENT_REQUESTS:
      return getClientRequestsTableSort;
    case TableKey.CLIENT_COMPANIES:
      return getClientCompaniesTableSort;
    case TableKey.USER_CLIENT_COMPANIES:
      return getUserClientCompaniesTableSort;
    case TableKey.INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE:
      return getInertWasteApplicationForPriorAcceptanceTableSort;
    default:
      // eslint-disable-next-line react/display-name
      return () => null;
  }
};

export const getDeliveryNotesTableSort = (rootState: RootState): TableSort | null =>
  rootState.tables[TableKey.DELIVERY_NOTES].sort;

export const getInvoicesTableSort = (rootState: RootState): TableSort | null =>
  rootState.tables[TableKey.INVOICES].sort;

export const getClientRequestsTableSort = (rootState: RootState): TableSort | null =>
  rootState.tables[TableKey.CLIENT_REQUESTS].sort;

export const getBusinessAdminClientRequestsTableSort = (rootState: RootState): TableSort | null =>
  rootState.tables[TableKey.BUSINESS_ADMIN_CLIENT_REQUESTS].sort;

export const getClientCompaniesTableSort = (rootState: RootState): TableSort | null =>
  rootState.tables[TableKey.CLIENT_COMPANIES].sort;

export const getUserClientCompaniesTableSort = (rootState: RootState): TableSort | null =>
  rootState.tables[TableKey.USER_CLIENT_COMPANIES].sort;

export const getInertWasteApplicationForPriorAcceptanceTableSort = (
  rootState: RootState,
): TableSort | null => rootState.tables[TableKey.INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE].sort;
