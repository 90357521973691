import { TableFilters } from '../filtersKeys';

export enum ClientRequestsFiltersKeys {
  REFERENCE = 'reference',
  TITLE = 'title',
  CLIENT_COMPANY_NAME = 'clientCompany.name',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  AUTHOR = 'author',
  RECIPIENT = 'recipient',
}

export const CLIENT_REQUESTS_FILTERS_INITIAL_VALUES: TableFilters = {
  [ClientRequestsFiltersKeys.REFERENCE]: '',
  [ClientRequestsFiltersKeys.CLIENT_COMPANY_NAME]: '',
  [ClientRequestsFiltersKeys.TITLE]: '',
  [ClientRequestsFiltersKeys.CREATED_AT]: { before: null, after: null },
  [ClientRequestsFiltersKeys.UPDATED_AT]: { before: null, after: null },
  [ClientRequestsFiltersKeys.AUTHOR]: '',
  [ClientRequestsFiltersKeys.RECIPIENT]: '',
};
