import styled, { css } from 'styled-components';
import { colorPalette, getSpacing, typography } from 'stylesheet';

export const LateralMenuContainer = styled.menu`
  width: ${getSpacing(10)};
  display: flex;
  flex-direction: column;
  padding: ${getSpacing(9)} 0 ${getSpacing(7)} 0;
  justify-content: space-between;
  background-color: ${colorPalette.primary};
`;

export const LateralMenuTopContainer = styled.div``;

export const LateralMenuBottomContainer = styled.div``;

export const lateralMenuStyle = {
  itemContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: inherit;
    margin-bottom: ${getSpacing(4)};
    color: ${colorPalette.grey_400};
    text-decoration: none;
    &:hover,
    &:focus {
      color: ${colorPalette.white};
      cursor: pointer;
    }
  `,
  itemLabel: css`
    ${typography.p3_regular};
    margin-top: ${getSpacing(1)};
    color: currentColor;
    text-align: center;
  `,
};
