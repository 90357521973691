import styled from 'styled-components';
import { colorPalette, getSpacing, typography } from 'stylesheet';

export const HomePageContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

export const HomeImage = styled.img`
  width: 215px;
  height: 161px;
`;

export const Title = styled.h1`
  margin-top: ${getSpacing(4)};
  ${typography.title_small};
  margin-bottom: ${getSpacing(10)};
  > #highlightedName {
    text-transform: capitalize;
    color: ${colorPalette.secondary_600};
  }
`;

export const MenuSectionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${getSpacing(5)};
`;
