import { createMuiTheme } from '@material-ui/core';
import { colorPalette, fontFamily, fontSize, getSpacing } from 'stylesheet';

export const muiTheme = createMuiTheme({
  overrides: {
    MuiPickersDay: {
      daySelected: {
        backgroundColor: colorPalette.primary,
        '&:hover': {
          backgroundColor: colorPalette.secondary_600,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        fontFamily: fontFamily.main,
        fontSize: fontSize.p1,
        lineHeight: '1.5',
        backgroundColor: colorPalette.white,
        padding: 0,
        '&$error': {
          borderColor: colorPalette.status_error,
        },
      },
      input: {
        padding: `${getSpacing(1)} ${getSpacing(2)}`,
      },
      multiline: {
        padding: `${getSpacing(1)} ${getSpacing(2)}`,
      },
      adornedEnd: {
        paddingRight: 0,
      },
    },
    MuiFormHelperText: {
      contained: {
        margin: `${getSpacing(0.5)} 0`,
        marginLeft: 0,
        fontFamily: fontFamily.main,
        fontSize: fontSize.p3,
        lineHeight: '1.5',
        color: colorPalette.status_error,
      },
      root: {
        fontFamily: fontFamily.main,
      },
    },
    MuiInputBase: {
      input: {
        height: 'auto',
      },
    },
    MuiTypography: {
      root: {
        fontFamily: fontFamily.main,
      },
      body1: {
        fontFamily: fontFamily.main,
      },
    },
  },
});
