import { TableFilters } from '../filtersKeys';

export enum InertWasteApplicationForPriorAcceptanceFiltersKeys {
  ID = 'id',
  REFERENCE = 'reference',
  TITLE = 'title',
  CREATED_AT = 'createdAt',
  AUTHOR_FULL_NAME = 'author.fullName',
  STATUS_CODE = 'status.code',
}

export const INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_FILTERS_INITIAL_VALUES: TableFilters = {
  [InertWasteApplicationForPriorAcceptanceFiltersKeys.REFERENCE]: '',
  [InertWasteApplicationForPriorAcceptanceFiltersKeys.ID]: '',
  [InertWasteApplicationForPriorAcceptanceFiltersKeys.TITLE]: '',
  [InertWasteApplicationForPriorAcceptanceFiltersKeys.CREATED_AT]: { before: null, after: null },
  [InertWasteApplicationForPriorAcceptanceFiltersKeys.AUTHOR_FULL_NAME]: '',
  [InertWasteApplicationForPriorAcceptanceFiltersKeys.STATUS_CODE]: '',
};
