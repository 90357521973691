export type GenerateDownloadPresignedUrlRequestBody = {
  clientCompanyId?: number;
  legalCorporationId: string;
  blobUrl: string;
  downloadedFileName: string;
  isForPreview: boolean;
};

export type GenerateUploadPresignedUrlRequestBody = {
  clientCompanyId?: number;
  legalCorporationId: string;
  blobPath: string;
};

export const APP_DOCUMENTS_FOLDER_NAME = 'document';

export enum APP_DOCUMENTS_SUBFOLDER_NAME {
  CLIENT_REQUEST = 'clientrequest',
  SITE_DOCUMENT = 'sitedocument',
  INERT_WASTE_APPLICATION = 'inertWasteApplication',
}
