import { makeStyles } from '@material-ui/core';
import { colorPalette } from 'stylesheet';

type StyledCircularProgressProps = { position: 'absolute' | 'static' };

export const useCircularProgressStyle = ({ position }: StyledCircularProgressProps) =>
  makeStyles({
    root: { position: position },
    colorPrimary: {
      color: colorPalette.primary,
    },
  });
