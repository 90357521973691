import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';

import { PLATFORM_LEGAL_CORPORATION_ID } from '../api/constants';
import { APPINSIGHT_KEY } from '../api/constants';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: `InstrumentationKey=${APPINSIGHT_KEY}`,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: false,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
  },
});
appInsights.loadAppInsights();

const trackException = (messageContext: string, error: any) => {
  let instance = 'REACT';
  const message: any = {
    Message: messageContext + ' - ' + error?.message,
    LegalCorporationId: PLATFORM_LEGAL_CORPORATION_ID,
  };
  if (error?.stack) {
    message['StackTrace'] = error?.stack;
  }
  if (error?.message === 'Network Error') {
    instance = 'PHP Down';
  }

  if (error?.config) {
    const config = error?.config;
    message['URL'] = config?.baseURL + config?.url;
  }

  if (error?.response?.data) {
    const data = error?.response?.data;
    instance =
      data?.message?.includes('SQLSTATE') && Number(data?.message?.match(/\[(-?\d+)\]/)[1]) >= 2000
        ? 'MYSQL Down'
        : data?.message?.includes('SQLSTATE') &&
          Number(data?.message?.match(/\[(-?\d+)\]/)[1]) < 2000
        ? 'MYSQL'
        : 'PHP';
    if (data?.stack_trace) {
      message['StackTrace'] = data?.stack_trace;
    }
    if (data?.message) {
      message['Message'] = data?.message;
    }
    if (message.Message.includes('Request failed with status code 502')) {
      instance = 'PHP DOWN';
    }
  }
  if (
    message.Message.includes('authentication exception') ||
    message.Message.includes('User does not have an existing session') ||
    message.Message.includes('GA4React intialization failed') ||
    message.Message.includes('Request aborted')
  ) {
    instance = '';
  }
  appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
    env.tags = env.tags || [];
    env.tags['ai.cloud.role'] = 'Yellowstone';
    env.tags['ai.cloud.roleInstance'] = instance;
  });

  appInsights.trackException(error, message);
};

export { reactPlugin, appInsights, trackException };
