import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

export type AuthenticationStatus = {
  isLoading: boolean;
  isAuthenticated: boolean;
};

export const useAuthenticationStatus = (): AuthenticationStatus => {
  const { accounts, inProgress } = useMsal();

  if (inProgress !== InteractionStatus.None) {
    return { isLoading: true, isAuthenticated: false };
  }

  if (accounts.length > 0) {
    return { isLoading: false, isAuthenticated: true };
  }
  return { isLoading: false, isAuthenticated: false };
};
