import { filtersToQueryParams } from '../utils/filtersToQueryParams';
import {
  ApiFilterQueryParamGeneratorInterface,
  EXCLUDED_IDENTIFIER_PROPERTY,
  QueryParamGeneratorProps,
  SELECTED_IDENTIFIER_PROPERTY,
} from './ApiFilterQueryParamGeneratorInterface';

export class ApiPlatformFilterQueryParamGenerator implements ApiFilterQueryParamGeneratorInterface {
  format({
    pageNumber,
    filters,
    sort,
    selectAll,
    selectedIds,
    isInvoice = false,
  }: QueryParamGeneratorProps): string {
    let queryStringFromSelectAllAndSelectedIdentifiers = '';
    if (selectedIds !== undefined) {
      queryStringFromSelectAllAndSelectedIdentifiers = this.formatQueryParamForSelectAllAndSelectedIds(
        selectAll,
        selectedIds,
      );
    }

    return `?${pageNumber ? `page=${pageNumber}` : ''}${
      filters ? `${filtersToQueryParams(filters)}` : ''
    }${
      sort ? `&sort[${sort.key}]=${sort.value}` : ''
    }${queryStringFromSelectAllAndSelectedIdentifiers}${
      isInvoice ? `&isInvoice=${isInvoice}` : ''
    }`;
  }

  formatQueryParamForSelectAllAndSelectedIds(
    selectAll: boolean | undefined,
    selectedIds: string[],
  ): string {
    let queryParams = '';

    if (selectedIds.length > 0) {
      for (const selectedId of selectedIds) {
        queryParams += `&${
          selectAll ? EXCLUDED_IDENTIFIER_PROPERTY : SELECTED_IDENTIFIER_PROPERTY
        }[]=${selectedId}`;
      }
      return queryParams;
    }

    if (selectAll) {
      return queryParams;
    }

    throw new Error('SelectedIds cannot be empty if select all is false or undefined.');
  }
}
