import { makeStyles } from '@material-ui/core';
import { colorPalette, fontFamily, fontSize, getSizeInPx, getSpacing } from 'stylesheet';

import { AdditionalButtonProps } from './Button';

export const useButtonStyle = ({
  margins,
  bigPaddings,
  displayStyle,
  isLoading,
  children,
  justifyContentLeft,
  widthInPx,
  allSidePadding,
}: AdditionalButtonProps) =>
  makeStyles({
    root: {
      fontFamily: fontFamily.main,
      fontSize: fontSize.p1,
      height: getSpacing(5),
      ...(widthInPx && { width: `${getSizeInPx(widthInPx)}` }),
      textTransform: 'inherit',
      letterSpacing: 'inherit',
      margin: margins ?? '0',
      padding: allSidePadding
        ? allSidePadding
        : bigPaddings
        ? `${getSpacing(1)} ${getSpacing(5)}`
        : `${getSpacing(1)} ${getSpacing(4)}`,
      borderRadius: getSpacing(1),
      '& .MuiButton-label': {
        justifyContent: justifyContentLeft ? 'flex-start' : 'inherit',
        ...(isLoading && {
          position: 'relative',
          visibility: 'hidden',
          '& .MuiCircularProgress-root': {
            visibility: 'visible',
          },
        }),
      },
      ...(isLoading && {
        pointerEvents: 'none',
      }),

      color: () => {
        switch (displayStyle) {
          case 'filledOrange':
          case 'filledRed':
            return colorPalette.white;
          case 'unfilledGrey':
          case 'unfilledOrange':
            return colorPalette.greyMain;
        }
      },
      border: () => {
        switch (displayStyle) {
          case 'filledOrange':
          case 'unfilledOrange':
            return `solid 1px ${colorPalette.secondary_600}`;
          case 'unfilledGrey':
            return `solid 1px ${colorPalette.greyMain}`;
          case 'filledRed':
            return `solid 1px ${colorPalette.status_error}`;
        }
      },
      backgroundColor: () => {
        switch (displayStyle) {
          case 'filledOrange':
            return colorPalette.secondary_600;
          case 'filledRed':
            return colorPalette.status_error;
          case 'unfilledGrey':
          case 'unfilledOrange':
            return colorPalette.white;
        }
      },
      '&:hover,&:focus-visible,&:hover .MuiButton-startIcon,&:focus-visible .MuiButton-startIcon': {
        color: colorPalette.white,
      },
      '&:hover,&:focus-visible': {
        border: () => {
          switch (displayStyle) {
            case 'filledOrange':
              return `solid 1px ${colorPalette.secondary_300}`;
            case 'filledRed':
              return `solid 1px ${colorPalette.status_error_light}`;
            case 'unfilledGrey':
              return `solid 1px ${colorPalette.blue_400}`;
            case 'unfilledOrange':
              return `solid 1px ${colorPalette.secondary_600}`;
          }
        },
        backgroundColor: () => {
          switch (displayStyle) {
            case 'filledOrange':
              return colorPalette.secondary_300;
            case 'filledRed':
              return colorPalette.status_error_light;
            case 'unfilledGrey':
              return colorPalette.blue_400;
            case 'unfilledOrange':
              return colorPalette.secondary_600;
          }
        },
      },
      '&:disabled': {
        border: () => {
          switch (displayStyle) {
            case 'filledOrange':
            case 'filledRed':
              return `solid 1px ${colorPalette.grey_400}`;
            case 'unfilledGrey':
            case 'unfilledOrange':
              return `solid 1px ${colorPalette.grey_600}`;
          }
        },
        backgroundColor: () => {
          switch (displayStyle) {
            case 'filledOrange':
            case 'filledRed':
              return colorPalette.grey_400;
            case 'unfilledGrey':
            case 'unfilledOrange':
              return colorPalette.white;
          }
        },
      },
      '&:disabled,&:disabled .MuiButton-startIcon': {
        color: colorPalette.grey_600,
      },
    },
    startIcon: {
      color: () => {
        switch (displayStyle) {
          case 'filledOrange':
          case 'filledRed':
            return colorPalette.white;
          case 'unfilledGrey':
            return colorPalette.greyMain;
          case 'unfilledOrange':
            return colorPalette.secondary_600;
        }
      },
      marginLeft: 0,
      marginRight: children ? getSpacing(2) : 0,
    },
  });
