import { LateralMenuButtonContainer, LateralMenuButtonLabel } from './LateralMenuButton.style';

export type LateralMenuButtonProps = {
  label: string;
  icon: JSX.Element;
  onButtonClick: () => void;
};

export const LateralMenuButton = ({
  label,
  icon,
  onButtonClick,
}: LateralMenuButtonProps): JSX.Element => {
  return (
    <LateralMenuButtonContainer onClick={onButtonClick}>
      {icon}
      <LateralMenuButtonLabel>{label}</LateralMenuButtonLabel>
    </LateralMenuButtonContainer>
  );
};
