import { makeStyles } from '@material-ui/core';
import { colorPalette } from 'stylesheet';

import { AdditionalIconButtonProps } from './IconButton';

export const useIconButtonStyle = ({ displayStyle }: AdditionalIconButtonProps) =>
  makeStyles({
    root: {
      color: () => {
        switch (displayStyle) {
          case 'grey':
            return colorPalette.greyMain;
          case 'orange':
            return colorPalette.secondary_600;
          case 'blue':
            return colorPalette.blue_600;
          case 'red':
            return colorPalette.status_error;
        }
      },
      width: '50px',
      height: '50px',
    },
    sizeSmall: {
      width: '40px',
      height: '40px',
    },
  });
