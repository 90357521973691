import { TableFilters, TableSort } from '../../../redux/Tables/filtersKeys';
import { filtersToQueryParams } from '../../utils/filtersToQueryParams';
import { apiClient } from '../apiClient';
import { getClientCompanySingleLegalCorporationId } from '../common';
import { ApiPageableResponse } from '../types';
import {
  normalizeCreateClientRequestFormForRequestBody,
  normalizeCreateClientRequestMessageFormForRequestBody,
} from './normalizer';
import {
  ApiClientRequestInDetails,
  ApiClientRequestInList,
  ApiCountClientRequestUnread,
  CreateClientRequestForm,
  CreateClientRequestMessageForm,
} from './types';

export const createClientRequest = (
  createClientRequestForm: CreateClientRequestForm,
  clientCompanyId: number,
  legalCorporationId: string,
  isBackofficeIssuer: boolean,
) => {
  legalCorporationId = getClientCompanySingleLegalCorporationId(legalCorporationId);
  const data = normalizeCreateClientRequestFormForRequestBody(
    createClientRequestForm,
    clientCompanyId,
    legalCorporationId,
    isBackofficeIssuer,
  );
  return apiClient.request<null>('POST', `/client-request`, data);
};

export const createClientRequestMessage = (
  createClientRequestMessageForm: CreateClientRequestMessageForm,
  clientCompanyId: number,
  legalCorporationId: string,
  clientRequestId: number,
) => {
  legalCorporationId = getClientCompanySingleLegalCorporationId(legalCorporationId);
  const data = normalizeCreateClientRequestMessageFormForRequestBody(
    createClientRequestMessageForm,
    clientCompanyId,
    legalCorporationId,
    clientRequestId,
  );
  return apiClient.request<null>('POST', `/client-request/create-message`, data);
};

export const getAllClientRequestsByPageAndClientCompany = (
  pageNumber: number,
  clientCompanyId: number,
  legalCorporationId: string,
  filters?: TableFilters,
  sort?: TableSort | null,
) => {
  return apiClient.request<ApiPageableResponse<ApiClientRequestInList>>(
    'GET',
    `/client-requests?legalCorporation.id=${legalCorporationId}&clientCompany.id=${clientCompanyId}&page=${pageNumber}${
      filters ? filtersToQueryParams(filters) : ''
    }${sort ? `&sort[${sort.key}]=${sort.value}` : ''}`,
  );
};

export const getClientRequestById = (clientRequestId: number) => {
  return apiClient.request<ApiClientRequestInDetails>('GET', `/client-requests/${clientRequestId}`);
};

export const setApiClientRequestAsRead = (clientRequestId: number) => {
  return apiClient.request<null>(
    'POST',
    `/client-request/set-read?clientRequest=${clientRequestId}`,
  );
};

export const getNumberOfUnreadMessageForPermission = (permissionId: string) => {
  return apiClient.request<ApiCountClientRequestUnread>(
    'GET',
    `/client-request-view/number-unread-messages?permission=${permissionId}`,
  );
};
