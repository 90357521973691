import {
  ACCESS_TOKEN_SCOPES,
  MSAL_REDIRECT_PARAMETERS,
  msalInstance,
} from 'services/authentication/msalConfig';
import { logger } from 'services/logs/logger';

export const acquireAccessToken = async () => {
  const accounts = msalInstance.getAllAccounts();

  if (accounts.length === 0) {
    msalInstance.loginRedirect(MSAL_REDIRECT_PARAMETERS);
  }
  try {
    const authResult = await msalInstance.acquireTokenSilent({
      scopes: ACCESS_TOKEN_SCOPES,
      account: accounts[0],
    });
    return authResult.accessToken;
  } catch (error) {
    logger.logError('Error during Azure AD token acquisition', error);
    msalInstance.loginRedirect(MSAL_REDIRECT_PARAMETERS);
  }
};
