import { trackException } from './ApplicationInsights';
import { LoggerInterface } from './LoggerInterface';

export class AppInsightsLogger implements LoggerInterface {
  init(): void {
    return;
  }

  logError(message: string, context?: Record<string, unknown>): void {
    console.error(message, context);
    trackException(message, context);
  }

  logInfo(message: string, context?: Record<string, unknown>): void {
    console.log(message, context);
  }

  logWarn(message: string, context?: Record<string, unknown>): void {
    console.warn(message, context);
  }
}
