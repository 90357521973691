import { Loader } from '../../Loader/Loader';
import { StyledFullScreenLoaderContainer } from './FullScreenLoader.style';

type Props = {
  isVisible: boolean;
};
export const FullScreenLoader = ({ isVisible }: Props): JSX.Element => {
  return (
    <StyledFullScreenLoaderContainer open={isVisible}>
      <Loader />
    </StyledFullScreenLoaderContainer>
  );
};
