export const formatDate = (isoStringDatetime: string) => {
  const date = new Date(extractDateFromDateTime(isoStringDatetime));
  if (isNaN(date.getTime())) {
    return '-';
  }

  return date.toLocaleDateString('en-US', { timeZone: 'UTC' });
};

export const formatCurrentDateForFileName = () => new Date().toISOString().substr(0, 10);

const extractDateFromDateTime = (dateTime: string) => dateTime.substring(0, 10);
