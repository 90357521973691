/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.ts, reducers wouldn't be hot reloadable.
 */

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import { apiResponseStatusGlobalDisplayReducer } from './ApiResponseStatusGlobalDisplay/slice';
import { tablesReducer } from './Tables/slice';
import { userPersistConfig, userReducer } from './User/slice';

export const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, userReducer),
  tables: tablesReducer,
  apiResponseStatusGlobalDisplay: apiResponseStatusGlobalDisplayReducer,
});
