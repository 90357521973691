const CLIENT_COMPANY_BASE_ROUTE = '/client-company/:clientCompanyId(\\d+)';
const SUBSCRIPTION_REQUEST_BASE_ROUTE = '/subscription-request';
const USERS = '/users';
const HISTORY = '/history';
const BUSINESS_REFERENTS = '/business-referents';
const CLIENT_REQUESTS = '/client-requests';
const BUSINESS_ADMIN_CLIENT_REQUESTS = '/business-admin-client-requests';

export const OPEN_CREATE_INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_SIDE_PANEL_QUERY_PARAM =
  'openCreateDAP';

export const ROUTES = {
  HOME: '/home',
  HISTORY: HISTORY,
  CLIENT_COMPANIES: '/client-companies',
  CLIENT_COMPANY: CLIENT_COMPANY_BASE_ROUTE,
  USERS: USERS,
  CLIENT_COMPANY_USERS: `${CLIENT_COMPANY_BASE_ROUTE}${USERS}`,
  CLIENT_COMPANY_HISTORY: `${CLIENT_COMPANY_BASE_ROUTE}${HISTORY}`,
  CLIENT_COMPANY_BUSINESS_REFERENTS: `${CLIENT_COMPANY_BASE_ROUTE}${BUSINESS_REFERENTS}`,
  SITES_AND_DOCUMENTS: '/sites-and-documents',
  CLIENT_REQUESTS: CLIENT_REQUESTS,
  INVITATION_PROCESS_END: '/invitation-process-end',
  NOT_FOUND: '/not-found',
  SUBSCRIPTION_REQUEST: SUBSCRIPTION_REQUEST_BASE_ROUTE,
  SUBSCRIPTION_REQUEST_SUCCESS: `${SUBSCRIPTION_REQUEST_BASE_ROUTE}/success`,
  HEALTH_CHECK_FRONTEND: '/health-check-frontend',
  BUSINESS_ADMIN_CLIENT_REQUESTS: BUSINESS_ADMIN_CLIENT_REQUESTS,
  BUSINESS_REFERENTS: `${BUSINESS_REFERENTS}`,
  CLIENT_REQUESTS_SEARCH_SUFFIX: {
    //INERT_WASTE_APPLICATION_TAB_WITH_CREATE_SIDE_PANEL_OPEN: `?tab=0&${OPEN_CREATE_INERT_WASTE_APPLICATION_FOR_PRIOR_ACCEPTANCE_SIDE_PANEL_QUERY_PARAM}=true`,
    //INERT_WASTE_APPLICATION_TAB: `?tab=0`,
    OTHER_REQUESTS_TAB: `?tab=0`,
  },
};
