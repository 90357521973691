import { useSelector } from 'react-redux';

import {
  getUserApplication,
  getUserClientCompanyIsInternal,
  getUserRoleCode,
  UserApplication,
} from './selectors';
import { AuthorizationConfig } from './types';

type PartialAuthorizationConfig = Partial<AuthorizationConfig>;

export const useAuthorizationService = () => {
  const userRoleCode = useSelector(getUserRoleCode);
  const userApplication = useSelector(getUserApplication);
  const userIsCompanyInternal = useSelector(getUserClientCompanyIsInternal);

  return ({
    applications = [UserApplication.BACK_OFFICE, UserApplication.FRONT_OFFICE],
    roles,
    isAllowedForInternalCompany = true,
  }: PartialAuthorizationConfig) => {
    if (applications.includes(userApplication)) {
      if (!isAllowedForInternalCompany && !!userIsCompanyInternal) {
        return false;
      }
      if (roles) {
        return !!userRoleCode && roles.includes(userRoleCode);
      }
      return true;
    }

    return false;
  };
};
