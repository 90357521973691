import { useMsal } from '@azure/msal-react';
import { CustomerAgreementDialog } from 'components/CustomerAgreementDialog/CustomerAgreementDialog';
import { ElementType } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useBoolean } from 'react-use';
import { shouldSignLatestTermsOfAgreement } from 'redux/User/selectors';
import { AuthorizationConfig } from 'redux/User/types';
import { useAuthorizationService } from 'redux/User/useAuthorizationService';
import { MSAL_REDIRECT_PARAMETERS } from 'services/authentication/msalConfig';
import { useAuthenticationStatus } from 'services/authentication/useAuthenticationStatus';

import { ROUTES } from './routes';

type Props = Omit<RouteProps, 'component'> &
  AuthorizationConfig & {
    component: ElementType;
  };

export const ProtectedRoute = ({
  path,
  component: Component,
  exact,
  applications,
  roles,
  isAllowedForInternalCompany,
}: Props) => {
  const { instance } = useMsal();
  const authenticationStatus = useAuthenticationStatus();
  const isUserAuthorized = useAuthorizationService()({
    applications,
    roles,
    isAllowedForInternalCompany,
  });
  const shouldOpenCustomerAgreementDialog = useSelector(shouldSignLatestTermsOfAgreement);
  const [hasAcceptedAgreements, setHasAcceptedAgreements] = useBoolean(false);

  if (authenticationStatus.isLoading) {
    return <></>;
  }

  if (!authenticationStatus.isAuthenticated) {
    instance.loginRedirect(MSAL_REDIRECT_PARAMETERS);
    return <></>;
  }

  if (!isUserAuthorized) {
    return <Redirect to={ROUTES.NOT_FOUND} />;
  }

  return (
    <Route
      exact={exact}
      path={path}
      render={(routeProps) => (
        <>
          <Component {...routeProps} />
          <CustomerAgreementDialog
            isOpen={shouldOpenCustomerAgreementDialog && !hasAcceptedAgreements}
            closeCustomerAgreementDialog={() => setHasAcceptedAgreements(true)}
          />
        </>
      )}
    />
  );
};
