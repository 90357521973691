import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ApiResponseStatusGlobalDisplayState = {
  isLoading: boolean;
  isError: boolean;
};

const initialState: ApiResponseStatusGlobalDisplayState = {
  isLoading: false,
  isError: false,
};

const apiResponseStatusGlobalDisplaySlice = createSlice({
  name: 'apiResponseStatus',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsError: (state, action: PayloadAction<boolean>) => {
      state.isError = action.payload;
    },
  },
});

export const { setIsLoading, setIsError } = apiResponseStatusGlobalDisplaySlice.actions;
export const apiResponseStatusGlobalDisplayReducer = apiResponseStatusGlobalDisplaySlice.reducer;
