import ReactGA from 'react-ga4';
import {
  GOOGLE_ANALYTICS_TRACKIND_ID,
  PLATFORM_LEGAL_CORPORATION_ID,
  PLATFORM_LEGAL_CORPORATION_LABEL,
} from 'services/api/constants';

import { AnalyticsInterface } from './AnalyticsInterface';

export class GoogleAnalytics implements AnalyticsInterface {
  async init(): Promise<void> {
    ReactGA.initialize([
      {
        trackingId: GOOGLE_ANALYTICS_TRACKIND_ID,
      },
    ]);
  }

  setUserConfig(userDetails: any): void {
    if (userDetails) {
      const userEmail = userDetails?.userId;
      const userType =
        userEmail.match(/@colas/gi) ||
        userEmail.match(/@groupecolas/gi) ||
        userEmail.match(/@barrettpaving/gi) ||
        userEmail.match(/@barrettindustries/gi) ||
        userEmail.match(/@iaconstruction/gi) ||
        userEmail.match(/@strawserconstruction/gi) ||
        userEmail.match(/@hriinc/gi) ||
        userEmail.match(/@upstonematerials/gi) ||
        userEmail.match(/@sully-miller/gi) ||
        userEmail.match(/@millergroup/gi) ||
        userEmail.match(/@reevescc/gi) ||
        userEmail.match(/@deltacos/gi)
          ? 'Internal'
          : 'External';
      ReactGA.gtag('set', 'user_properties', {
        legal_corporation: PLATFORM_LEGAL_CORPORATION_LABEL,
        user_role: userDetails?.role?.label,
        user_identifier: userDetails?.userMetadata?.id,
        legal_corporation_id: PLATFORM_LEGAL_CORPORATION_ID,
        user_type: userType,
        // user_id: userDetails?.userMetadata?.id,
      });
    }
  }

  event(action: string, label: string, data: string): void {
    ReactGA.event({
      category: data,
      action: action,
      label: label,
    });
  }
}
