import axios from 'axios';

import { apiClient } from '../apiClient';
import { PLATFORM_LEGAL_CORPORATION_ID } from '../constants';
import {
  GenerateDownloadPresignedUrlRequestBody,
  GenerateUploadPresignedUrlRequestBody,
} from './types';

export const getDownloadPresignedUrl = (
  documentUrl: string,
  fileName: string,
  isForPreview: boolean,
  clientCompanyId?: number,
) => {
  const data: GenerateDownloadPresignedUrlRequestBody = {
    legalCorporationId: PLATFORM_LEGAL_CORPORATION_ID,
    clientCompanyId: clientCompanyId,
    blobUrl: documentUrl,
    isForPreview,
    downloadedFileName: fileName.replace(/[^a-zA-Z  0-9 _ .]/g, ''),
  };
  return apiClient.request<string>('POST', `/documents/generate-download-presigned-url`, data);
};

export const getUploadPresignedUrl = (documentPath: string, clientCompanyId?: number) => {
  const data: GenerateUploadPresignedUrlRequestBody = {
    legalCorporationId: PLATFORM_LEGAL_CORPORATION_ID,
    clientCompanyId: clientCompanyId,
    blobPath: documentPath,
  };
  return apiClient.request<string>('POST', `/documents/generate-upload-presigned-url`, data);
};

export const uploadFileToAzureStorage = (presignedUrl: string, file: File) => {
  return axios.put(presignedUrl, file, {
    headers: {
      'x-ms-blob-type': 'BlockBlob', // this header is required by Azure Storage
    },
  });
};
