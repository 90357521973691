import { MenuItem, MenuList } from '@material-ui/core';
import { LogOutIcon } from 'assets/icons/LogOutIcon';
import styled from 'styled-components';
import { colorPalette, getSpacing } from 'stylesheet';

export const MenuContainer = styled.div`
  padding: ${getSpacing(1)};
  border-radius: ${getSpacing(2)};
  background-color: ${colorPalette.white};
  border: 1px solid ${colorPalette.grey_300};
  border-top: none;
  width: 380px;
  margin-top: 18px;
`;

export const StyledMenuList = styled(MenuList)`
  overflow: auto;
  padding: 0;
`;

export const InvoiceNotificationContainer = styled.div`
  padding: ${getSpacing(1)};
  border-radius: ${getSpacing(2)};
  background-color: ${colorPalette.white};
  border: 1px solid ${colorPalette.grey_300};
`;

export const InvoiceNotificationType = styled.div`
  margin-left: 16px;
`;

export const NotificationHeader = styled.h1`
  font-weight: bold;
`;

export const StyledMenuItem = styled(MenuItem)`
  display: flex;
  border-radius: ${getSpacing(2)};
  padding: ${getSpacing(1)};
  &:hover {
    background-color: transparent;
  }
`;

export const StyledLogOutIcon = () => <LogOutIcon color={colorPalette.status_error} />;

export const MenuText = styled.span`
  margin-left: ${getSpacing(2)};
`;

export const DisconnectText = styled(MenuText)`
  color: ${colorPalette.status_error};
`;
