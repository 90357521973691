import { TableFilters } from '../filtersKeys';

export enum ClientCompaniesFiltersKeys {
  TYPE = 'isInternal',
  ID_MDM = 'id',
  CLIENT_COMPANY_NAME = 'name',
  SIRET = 'siret',
}

export const CLIENT_COMPANIES_FILTERS_INITIAL_VALUES: TableFilters = {
  [ClientCompaniesFiltersKeys.TYPE]: '',
  [ClientCompaniesFiltersKeys.ID_MDM]: '',
  [ClientCompaniesFiltersKeys.CLIENT_COMPANY_NAME]: '',
  [ClientCompaniesFiltersKeys.SIRET]: '',
};
