import { colorPalette, getSizeInPx } from 'stylesheet';

import { SvgIcon } from './SvgIcon';
import { IconProps } from './types';

export const InformationIcon = ({
  widthInPx,
  heightInPx,
  color = colorPalette.grey_500,
}: IconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 21 21"
    style={{
      width: getSizeInPx(widthInPx ?? 21),
      height: getSizeInPx(heightInPx ?? 21),
    }}
  >
    <path
      d="M9.82031 9.97156C9.82031 9.41927 10.268 8.97156 10.8203 8.97156C11.3726 8.97156 11.8203 9.41927 11.8203 9.97156V15.9716C11.8203 16.5238 11.3726 16.9716 10.8203 16.9716C10.268 16.9716 9.82031 16.5238 9.82031 15.9716V9.97156Z"
      fill={color}
    />
    <path
      d="M10.8203 5.04333C10.268 5.04333 9.82031 5.49105 9.82031 6.04333C9.82031 6.59562 10.268 7.04333 10.8203 7.04333C11.3726 7.04333 11.8203 6.59562 11.8203 6.04333C11.8203 5.49105 11.3726 5.04333 10.8203 5.04333Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8203 0.992188C5.29747 0.992188 0.820312 5.46934 0.820312 10.9922C0.820312 16.515 5.29747 20.9922 10.8203 20.9922C16.3432 20.9922 20.8203 16.515 20.8203 10.9922C20.8203 5.46934 16.3432 0.992188 10.8203 0.992188ZM2.82031 10.9922C2.82031 15.4105 6.40203 18.9922 10.8203 18.9922C15.2386 18.9922 18.8203 15.4105 18.8203 10.9922C18.8203 6.57391 15.2386 2.99219 10.8203 2.99219C6.40203 2.99219 2.82031 6.57391 2.82031 10.9922Z"
      fill={color}
    />
  </SvgIcon>
);
