import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
} from '@material-ui/core';

import { useIconButtonStyle } from './IconButton.style';

export type AdditionalIconButtonProps = {
  displayStyle: IconButtonDisplayStyle;
};

export type IconButtonDisplayStyle = 'orange' | 'blue' | 'grey' | 'red';

type IconButtonProps = MuiIconButtonProps & AdditionalIconButtonProps;

export const IconButton = ({ displayStyle, children, ...props }: IconButtonProps) => {
  const classes = useIconButtonStyle({ displayStyle })();
  return (
    <MuiIconButton classes={classes} {...props}>
      {children}
    </MuiIconButton>
  );
};
