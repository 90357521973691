import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import { colorPalette, getSpacing, typography } from 'stylesheet';

interface DrawerStyleProps {
  widthInPercent?: number;
}

export const useDrawerStyle = ({ widthInPercent }: DrawerStyleProps) =>
  makeStyles({
    paper: {
      ...(widthInPercent ? { width: `${widthInPercent}%` } : { minWidth: `40%`, maxWidth: '60%' }),
      paddingLeft: getSpacing(5),
      paddingRight: getSpacing(5),
      paddingBottom: getSpacing(2),
      paddingTop: getSpacing(2),
      backgroundColor: colorPalette.backgroundMain,
    },
  });

export const SidePanelHeader = styled.div`
  min-height: ${getSpacing(11)};
  border-bottom: 1px solid ${colorPalette.grey_400};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleIconAndTitleContainer = styled.div`
  display: flex;
  align-items: center;
  ${typography.title_xsmall};
`;

export const TitleIconContainer = styled.div`
  width: ${getSpacing(5)};
  height: ${getSpacing(5)};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colorPalette.greyMain};
  margin-right: ${getSpacing(2)};
`;
