import { getSizeInPx } from 'stylesheet';

import { SvgIcon } from './SvgIcon';
import { IconProps } from './types';

export const ExchangeIcon = ({ widthInPx, heightInPx, color }: IconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 106 105"
    style={{
      width: getSizeInPx(widthInPx ?? 21),
      height: getSizeInPx(heightInPx ?? 20),
    }}
  >
    <path
      d="M89.4583 31.6667H84.25V16.0417C84.25 11.8977 82.6038 7.92346 79.6735 4.9932C76.7433 2.06295 72.769 0.416748 68.625 0.416748H16.5417C12.3976 0.416748 8.42337 2.06295 5.49311 4.9932C2.56286 7.92346 0.916656 11.8977 0.916656 16.0417V78.5417C0.921789 79.57 1.23118 80.5737 1.80583 81.4264C2.38048 82.2792 3.19468 82.9427 4.14582 83.3334C4.76394 83.625 5.44178 83.7677 6.12499 83.7501C6.81044 83.754 7.48993 83.6227 8.1245 83.3635C8.75907 83.1043 9.33624 82.7224 9.82291 82.2397L24.4583 67.5522H32.1667V75.0522C32.1667 79.1962 33.8129 83.1704 36.7431 86.1007C39.6734 89.031 43.6477 90.6772 47.7917 90.6772H83.8333L96.1771 103.073C96.6637 103.556 97.2409 103.938 97.8755 104.197C98.51 104.456 99.1895 104.587 99.875 104.583C100.558 104.601 101.236 104.458 101.854 104.167C102.805 103.776 103.619 103.112 104.194 102.26C104.769 101.407 105.078 100.403 105.083 99.3751V47.2917C105.083 43.1477 103.437 39.1735 100.507 36.2432C97.5766 33.3129 93.6023 31.6667 89.4583 31.6667ZM32.1667 47.2917V57.1355H22.3229C21.6375 57.1315 20.958 57.2629 20.3234 57.5221C19.6888 57.7813 19.1117 58.1632 18.625 58.6459L11.3333 65.9897V16.0417C11.3333 14.6604 11.8821 13.3357 12.8588 12.3589C13.8356 11.3821 15.1603 10.8334 16.5417 10.8334H68.625C70.0063 10.8334 71.3311 11.3821 72.3078 12.3589C73.2846 13.3357 73.8333 14.6604 73.8333 16.0417V31.6667H47.7917C43.6477 31.6667 39.6734 33.3129 36.7431 36.2432C33.8129 39.1735 32.1667 43.1477 32.1667 47.2917ZM94.6667 86.823L89.4583 81.6147C88.976 81.1225 88.4007 80.731 87.766 80.4628C87.1312 80.1946 86.4495 80.055 85.7604 80.0522H47.7917C46.4103 80.0522 45.0856 79.5034 44.1088 78.5267C43.1321 77.5499 42.5833 76.2252 42.5833 74.8438V47.2917C42.5833 45.9104 43.1321 44.5857 44.1088 43.6089C45.0856 42.6321 46.4103 42.0834 47.7917 42.0834H89.4583C90.8397 42.0834 92.1644 42.6321 93.1412 43.6089C94.1179 44.5857 94.6667 45.9104 94.6667 47.2917V86.823Z"
      fill={color ?? 'currentColor'}
    />
  </SvgIcon>
);
