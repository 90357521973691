import { Dialog, withStyles } from '@material-ui/core';
import { getSpacing } from 'stylesheet';

export const StyledFullScreenLoaderContainer = withStyles({
  root: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  paper: {
    backgroundColor: 'transparent',
    boxShadow: 'none',

    //Define width and height to avoid overflow
    width: getSpacing(6),
    height: getSpacing(6),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})(Dialog);
