import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { ApiPermissionInList } from 'services/api/permissions/types';
import { BUSINESS_ADMIN_ROLE_CODE } from 'services/api/roles/types';
import { findSelectedPermission } from 'services/utils/permissions';

export type UserState = {
  permissions: ApiPermissionInList[];
  selectedPermissionId: string;
  numberOfUnreadMessages: number;
  legalCorporationId: string;
};

const USER_STORE_KEY = 'user';
const SELECTED_PERMISSION_ID_KEY = 'selectedPermissionId';

export const userPersistConfig = {
  key: USER_STORE_KEY,
  whitelist: [SELECTED_PERMISSION_ID_KEY],
  storage,
};

const initialState: UserState = {
  permissions: [],
  [SELECTED_PERMISSION_ID_KEY]: '',
  numberOfUnreadMessages: 0,
  legalCorporationId: '',
};

const userSlice = createSlice({
  name: USER_STORE_KEY,
  initialState,
  reducers: {
    storePermissions: (state, action: PayloadAction<ApiPermissionInList[]>) => {
      let permissions = action.payload;

      const isPortalAdmin = permissions.every(
        (permission) => permission.role.code == BUSINESS_ADMIN_ROLE_CODE,
      );

      const permissionIds = permissions.map((permission) => permission.id);

      if (!isPortalAdmin) {
        const clientCompanyPermissions: any = {};
        permissions.map((permission: any) => {
          const clientCompanyId = permission?.clientCompany?.id || '';
          clientCompanyPermissions[clientCompanyId] = clientCompanyPermissions[clientCompanyId]
            ? `${clientCompanyPermissions[clientCompanyId]},${permission.id}`
            : permission.id.toString();
        });

        //Update permission id if the permissions has same clientcomapny in list
        permissions = permissions.map((permission) => {
          const clientCompanyId = permission?.clientCompany?.id || '';
          const permissionId = clientCompanyPermissions[clientCompanyId]
            .split(',')
            .sort()
            .join(',');
          return { ...permission, id: permissionId };
        });
        // Remove Duplicates
        permissions = permissions.filter(
          (value, index, self) =>
            index ===
            self.findIndex((indx) => indx?.clientCompany?.id === value?.clientCompany?.id),
        );
      }

      state.permissions = permissions;

      const persistedSelectedPermissionId = state.selectedPermissionId;

      const permissionMatchingPersistedSelectedPermissionId = findSelectedPermission(
        permissions,
        persistedSelectedPermissionId,
      );

      state.selectedPermissionId = isPortalAdmin
        ? permissionIds.join(',')
        : permissionMatchingPersistedSelectedPermissionId?.id.toString() || '';

      const legalCorporationIds = permissionMatchingPersistedSelectedPermissionId?.clientCompany?.clientCompanyLegalCorporations?.map(
        (legalCorp: any) => legalCorp.legalCorporation.id,
      );

      state.legalCorporationId = legalCorporationIds
        ? legalCorporationIds.join(',').toString()
        : '';
    },
    updateSelectedPermissionId: (state, action: PayloadAction<string>) => {
      state.selectedPermissionId = action.payload.toString();
    },
    updateClientCompanyLegalCorporationId: (state, action: PayloadAction<any>) => {
      state.legalCorporationId = action.payload.toString();
    },
    addCustomerAgreementToPermission: (
      state,
      action: PayloadAction<{ permissionId: number; version: number }>,
    ) => {
      const permission = findSelectedPermission(state.permissions, action.payload.permissionId);

      if (permission) {
        permission.customerAgreements.push({ version: action.payload.version });
      }
    },
    setNumberOfUnreadMessages: (
      state,
      action: PayloadAction<{ numberOfUnreadMessages: number }>,
    ) => {
      state.numberOfUnreadMessages = action.payload.numberOfUnreadMessages;
    },
    decreaseNumberOfUnreadMessages: (state) => {
      if (state.numberOfUnreadMessages > 0) {
        state.numberOfUnreadMessages--;
      }
    },
  },
});

export const {
  storePermissions,
  updateSelectedPermissionId,
  addCustomerAgreementToPermission,
  setNumberOfUnreadMessages,
  decreaseNumberOfUnreadMessages,
  updateClientCompanyLegalCorporationId,
} = userSlice.actions;
export const userReducer = userSlice.reducer;
