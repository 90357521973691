import { CircularProgress } from '@material-ui/core';
import { frameHeight } from 'stylesheet';

import { useCircularProgressStyle } from './Loader.style';

type Props = {
  sizeInPx?: number;
  color?: 'inherit' | 'primary';
  position?: 'absolute' | 'static';
};

export const Loader = ({ sizeInPx, color, position }: Props) => {
  const classes = useCircularProgressStyle({ position: position ?? 'static' })();
  return (
    <CircularProgress
      classes={classes}
      size={sizeInPx ?? frameHeight.loader}
      color={color ?? 'primary'}
    />
  );
};
