import {
  ApiResponseStatus,
  ApiResponseStatusSectionDisplay,
} from 'components/ApiResponseStatusSectionDisplay/ApiResponseStatusSectionDisplay';
import { Layout } from 'components/Layout/Layout';
import { useMenuPageConfigs } from 'hooks/useMenuPageConfigs';
import { useTarteAuCitron } from 'hooks/useTarteAuCitron';
import {
  BusinessReferentsPage,
  ClientCompanyBusinessReferentsPage,
  ClientCompanyHistoryPage,
  ClientCompanyMainPage,
  ClientCompanyUsersPage,
  InvitationProcessEndPage,
  SubscriptionRequestPage,
  SubscriptionRequestSuccessPage,
} from 'pages/lazyLoadedPages';
import { NotFoundPage } from 'pages/NotFound/NotFoundPage';
import { FunctionComponent, Suspense, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';
import { getSelectedPermission, UserApplication } from 'redux/User/selectors';
import { storePermissions, updateSelectedPermissionId } from 'redux/User/slice';
import { ProtectedRoute } from 'routing/ProtectedRoute';
import { ROUTES } from 'routing/routes';
import { analytics } from 'services/analytics/analytics';
import { getPermissionId } from 'services/api/common';
import { fetchUserPermissions } from 'services/api/permissions/client';
import { useAuthenticationStatus } from 'services/authentication/useAuthenticationStatus';
import { logger } from 'services/logs/logger';

export const App: FunctionComponent = () => {
  useTarteAuCitron();
  const intl = useIntl();
  const apiResponseStatusSectionDisplayConfiguration = {
    errorStatusTitle: intl.formatMessage({
      id: 'app.error-response.title',
    }),
    errorStatusDescription: intl.formatMessage({
      id: 'app.error-response.description',
    }),
  };
  const [apiResponseStatus, setApiResponseStatus] = useState(ApiResponseStatus.LOADING);
  const authenticationStatus = useAuthenticationStatus();
  const dispatch = useAppDispatch();
  const fetchAndStoreUserPermissions = useCallback(async () => {
    try {
      const permissions = await fetchUserPermissions();
      dispatch(storePermissions(permissions));
      setApiResponseStatus(ApiResponseStatus.SUCCESS);
      const pId = getPermissionId();
      if (pId) {
        dispatch(updateSelectedPermissionId(pId));
      }
    } catch (error) {
      logger.logError('Error fetching user permissions', error);
      setApiResponseStatus(ApiResponseStatus.ERROR);
    }
  }, [dispatch]);

  useEffect(() => {
    if (authenticationStatus.isLoading) {
      setApiResponseStatus(ApiResponseStatus.LOADING);
    } else {
      if (authenticationStatus.isAuthenticated) {
        fetchAndStoreUserPermissions();
      } else {
        setApiResponseStatus(ApiResponseStatus.SUCCESS);
      }
    }
  }, [
    fetchAndStoreUserPermissions,
    authenticationStatus.isLoading,
    authenticationStatus.isAuthenticated,
  ]);
  const menuPageConfigs = useMenuPageConfigs();

  const selectedPermission = useSelector(getSelectedPermission);

  useEffect(() => {
    if (selectedPermission) {
      analytics.setUserConfig(selectedPermission);
    }
  }, [selectedPermission]);

  return (
    <ApiResponseStatusSectionDisplay
      configuration={apiResponseStatusSectionDisplayConfiguration}
      status={apiResponseStatus}
    >
      <BrowserRouter>
        <Suspense
          fallback={
            <Layout>
              <ApiResponseStatusSectionDisplay status={ApiResponseStatus.LOADING} />
            </Layout>
          }
        >
          <Layout>
            <Switch>
              <Redirect exact from="/" to={ROUTES.HOME} />
              {menuPageConfigs.map((menuPageConfig) => {
                return (
                  <ProtectedRoute
                    exact
                    key={menuPageConfig.linkPath ?? menuPageConfig.path}
                    path={menuPageConfig.path}
                    component={menuPageConfig.pageComponent}
                    applications={menuPageConfig.applications}
                    roles={menuPageConfig.roles}
                    isAllowedForInternalCompany={menuPageConfig.isAllowedForInternalCompany}
                  />
                );
              })}
              <ProtectedRoute
                exact
                path={ROUTES.CLIENT_COMPANY}
                component={ClientCompanyMainPage}
                applications={[UserApplication.BACK_OFFICE]}
              />
              <ProtectedRoute
                exact
                path={ROUTES.CLIENT_COMPANY_USERS}
                component={ClientCompanyUsersPage}
                applications={[UserApplication.BACK_OFFICE]}
              />
              <ProtectedRoute
                exact
                path={ROUTES.CLIENT_COMPANY_HISTORY}
                component={ClientCompanyHistoryPage}
                applications={[UserApplication.BACK_OFFICE]}
              />
              <ProtectedRoute
                exact
                path={ROUTES.CLIENT_COMPANY_BUSINESS_REFERENTS}
                component={ClientCompanyBusinessReferentsPage}
                applications={[UserApplication.BACK_OFFICE]}
              />
              <ProtectedRoute
                exact
                path={ROUTES.BUSINESS_REFERENTS}
                component={BusinessReferentsPage}
                applications={[UserApplication.FRONT_OFFICE]}
              />
              <Route
                exact
                path={ROUTES.INVITATION_PROCESS_END}
                component={InvitationProcessEndPage}
              />
              <Route exact path={ROUTES.SUBSCRIPTION_REQUEST} component={SubscriptionRequestPage} />
              <Route
                exact
                path={ROUTES.SUBSCRIPTION_REQUEST_SUCCESS}
                component={SubscriptionRequestSuccessPage}
              />
              <Route exact path={ROUTES.NOT_FOUND} component={NotFoundPage} />
              <Route exact path={ROUTES.HEALTH_CHECK_FRONTEND} render={() => null} />
              <Route component={NotFoundPage} />
            </Switch>
          </Layout>
        </Suspense>
      </BrowserRouter>
    </ApiResponseStatusSectionDisplay>
  );
};
