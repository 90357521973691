import { TableFilters } from '../filtersKeys';

export enum DeliveryNotesFiltersKeys {
  CONSTRUCTION_SITE = 'constructionSite',
  IDENTIFIER = 'identifier',
  STORAGE_SITE = 'storageSite',
  VEHICULE_REGISTRATION = 'vehiculeRegistration',
  PRODUCT_LABEL = 'deliveryNoteItems.product.label',
  DELIVERED_AT = 'deliveredAt',
  IS_CANCELED = 'isCanceled',
  INVOICE_IDENTIFIER = 'invoiceDeliveryNotes.invoiceIdentifier',
  TRANSPORT = 'transport',
  PO_NUMBER = 'invoiceDeliveryNotes.poNumber',
}

export const DELIVERY_NOTES_FILTERS_INITIAL_VALUES: TableFilters = {
  [DeliveryNotesFiltersKeys.CONSTRUCTION_SITE]: '',
  [DeliveryNotesFiltersKeys.IDENTIFIER]: '',
  [DeliveryNotesFiltersKeys.STORAGE_SITE]: '',
  [DeliveryNotesFiltersKeys.VEHICULE_REGISTRATION]: '',
  [DeliveryNotesFiltersKeys.PRODUCT_LABEL]: '',
  [DeliveryNotesFiltersKeys.DELIVERED_AT]: { before: null, after: null },
  [DeliveryNotesFiltersKeys.IS_CANCELED]: '',
  [DeliveryNotesFiltersKeys.INVOICE_IDENTIFIER]: '',
  [DeliveryNotesFiltersKeys.TRANSPORT]: '',
  [DeliveryNotesFiltersKeys.PO_NUMBER]: '',
};
