import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

export const ErrorApiResponseImage = styled.img`
  width: 163px;
  height: 122px;
`;

export const DialogBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DialogErrorMessageContainer = styled.div`
  margin-top: ${getSpacing(1)};
  margin-bottom: ${getSpacing(3)};
`;
