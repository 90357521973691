import { GDPRIcon } from 'assets/icons/GDPRIcon';
import { Button } from 'components/Button/Button';
import { SidePanel } from 'components/SidePanel/SidePanel';
import { analytics } from 'services/analytics/analytics';
import {
  GOOGLE_ANALYTICS_ACTIONS,
  GOOGLE_ANALYTICS_CATEGORIES,
  GOOGLE_ANALYTICS_LABELS,
} from 'services/analytics/config';
import { GDPR_MAIL_ADDRESS, PLATFORM_LEGAL_CORPORATION_LABEL } from 'services/api/constants';
import { useIntlByKey } from 'services/i18n/hooks/useIntlByKey';

import {
  ButtonContainer,
  GDPRSidePanelContainer,
  LinkPart,
  MessageContainer,
  MessagePart,
  Signature,
} from './GDPRSidePanel.style';

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

export const GDPRSidePanel = ({ onClose, isOpen }: Props) => {
  const gDPRSidePanelIntl = useIntlByKey('header.user-card.GDPR');

  const sendGoogleAnalyticsGDPRDocumentClick = () => {
    analytics.event(
      GOOGLE_ANALYTICS_ACTIONS.GDPR_DOCUMENT_CLICK,
      GOOGLE_ANALYTICS_LABELS.CLICK,
      GOOGLE_ANALYTICS_CATEGORIES.GDPR,
    );
  };
  const sendGoogleAnalyticsGDPREmailClick = () => {
    analytics.event(
      GOOGLE_ANALYTICS_ACTIONS.GDPR_EMAIL_CLICK,
      GOOGLE_ANALYTICS_LABELS.CLICK,
      GOOGLE_ANALYTICS_CATEGORIES.GDPR,
    );
  };

  return (
    <SidePanel
      title={gDPRSidePanelIntl('title')}
      titleIcon={<GDPRIcon />}
      isOpen={isOpen}
      onClose={onClose}
      widthInPercent={50}
    >
      <GDPRSidePanelContainer>
        <MessageContainer>
          <MessagePart>
            {gDPRSidePanelIntl('first-part', {
              legalCorporationName: PLATFORM_LEGAL_CORPORATION_LABEL,
            })}
          </MessagePart>
          <LinkPart>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${process.env.PUBLIC_URL}/config/ppdp.pdf`}
              onClick={sendGoogleAnalyticsGDPRDocumentClick}
            >
              {gDPRSidePanelIntl('document-name', {
                legalCorporationName: PLATFORM_LEGAL_CORPORATION_LABEL,
              })}
            </a>
          </LinkPart>
          <MessagePart>{gDPRSidePanelIntl('second-part')}</MessagePart>
          <LinkPart>
            <a href={`mailto:${GDPR_MAIL_ADDRESS}`} onClick={sendGoogleAnalyticsGDPREmailClick}>
              {GDPR_MAIL_ADDRESS}
            </a>
          </LinkPart>
          <Signature>
            {gDPRSidePanelIntl('signature', {
              legalCorporationName: PLATFORM_LEGAL_CORPORATION_LABEL,
            })}
          </Signature>
        </MessageContainer>
        <ButtonContainer>
          <Button displayStyle={'filledOrange'} onClick={onClose}>
            {gDPRSidePanelIntl('close-button')}
          </Button>
        </ButtonContainer>
      </GDPRSidePanelContainer>
    </SidePanel>
  );
};
