import { TableFilters, TableSort } from '../../redux/Tables/filtersKeys';

export type QueryParamGeneratorProps = {
  pageNumber?: number;
  filters?: TableFilters;
  sort?: TableSort | null;
  selectAll?: boolean;
  selectedIds?: string[];
  isInvoice?: boolean;
};

export const EXCLUDED_IDENTIFIER_PROPERTY = 'excludedIdentifier';
export const SELECTED_IDENTIFIER_PROPERTY = 'selectedIdentifier';

export interface ApiFilterQueryParamGeneratorInterface {
  format: (queryParamGeneratorProps: QueryParamGeneratorProps) => string;
  formatQueryParamForSelectAllAndSelectedIds: (
    selectAll: boolean | undefined,
    selectedIds: string[],
  ) => string;
}
