import { ArrowIcon } from 'assets/icons/ArrowIcon';
import { IconButton } from 'components/IconButton/IconButton';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getInvoiceNotificationSetting, getUserRoleCode } from 'redux/User/selectors';
import { AuthenticatedUser } from 'services/authentication/useUserInformation';
import { useIntlByKey } from 'services/i18n/hooks/useIntlByKey';

import {
  USER_CARD_POPOVER_MENU_ARIA_CONTROLS,
  UserCardPopover,
} from '../UserCardPopover/UserCardPopover';
import {
  UserCardContainer,
  UserInformationContainer,
  UserNameContainer,
  UserRoleContainer,
} from './UserCard.style';

type UserCardProps = {
  user: AuthenticatedUser;
};

export const UserCard = ({ user }: UserCardProps): JSX.Element => {
  const userRoleCode: any = useSelector(getUserRoleCode);
  const invoiceNotificationSetting: any = useSelector(getInvoiceNotificationSetting);
  const rolecodeIntl = useIntlByKey('roles');
  const anchorRef = useRef<HTMLDivElement>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const displayedName = `${user.givenName} ${user.familyName}`;

  const handleToggle = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  return (
    <UserCardContainer
      aria-controls={isPopoverOpen ? USER_CARD_POPOVER_MENU_ARIA_CONTROLS : undefined}
      aria-haspopup="true"
      onClick={handleToggle}
    >
      <UserInformationContainer ref={anchorRef}>
        <UserNameContainer>{displayedName}</UserNameContainer>
        <UserRoleContainer>{rolecodeIntl(userRoleCode) ?? ''}</UserRoleContainer>
      </UserInformationContainer>
      <IconButton displayStyle="grey" size="small">
        <ArrowIcon widthInPx={14} heightInPx={9} arrowDirection={isPopoverOpen ? 'top' : 'down'} />
      </IconButton>
      <UserCardPopover
        isOpen={isPopoverOpen}
        close={() => setIsPopoverOpen(false)}
        anchorRef={anchorRef}
        invoiceNotificationSetting={invoiceNotificationSetting}
      />
    </UserCardContainer>
  );
};
