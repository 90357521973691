import { useMsal } from '@azure/msal-react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
//import { GDPRIcon } from 'assets/icons/GDPRIcon';
import { HelpAndContactIcon } from 'assets/icons/HelpAndContactIcon';
import { HelpAndContactSidePanel } from 'components/HelpAndContactSidePanel/HelpAndContactSidePanel';
import { Popover } from 'components/Popover/Popover';
import { ChangeEvent, KeyboardEvent, RefObject, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { setIsError } from 'redux/ApiResponseStatusGlobalDisplay/slice';
import { useAppDispatch } from 'redux/hooks';
import { getSelectedPermissionId, isBackOfficeUser } from 'redux/User/selectors';
import { upsertInvoiceNotificationSetting } from 'services/api/invoiceNotification/client';
import { InvoiceNotificationSetting } from 'services/api/invoiceNotification/types';
import { useIntlByKey } from 'services/i18n/hooks/useIntlByKey';
import { logger } from 'services/logs/logger';

import { GDPRSidePanel } from './GDPRSidePanel/GDPRSidePanel';
import {
  DisconnectText,
  InvoiceNotificationContainer,
  InvoiceNotificationType,
  MenuContainer,
  MenuText,
  NotificationHeader,
  StyledLogOutIcon,
  StyledMenuItem,
  StyledMenuList,
} from './UserCardPopover.style';

export const USER_CARD_POPOVER_MENU_ARIA_CONTROLS = 'user-card-popover-list-collapse';

interface Props {
  isOpen: boolean;
  close: () => void;
  anchorRef: RefObject<HTMLDivElement>;
  invoiceNotificationSetting: InvoiceNotificationSetting;
}

export const UserCardPopover = ({
  isOpen,
  close,
  anchorRef,
  invoiceNotificationSetting,
}: Props) => {
  const { instance } = useMsal();
  const dispatch = useAppDispatch();
  const userCardPopoverIntl = useIntlByKey('header.user-card.popover');
  const invoiceNotificationSettingIntl = useIntlByKey('header.notification');
  const isBackofficeUser = useSelector(isBackOfficeUser);
  const permissionId = useSelector(getSelectedPermissionId);

  const handleListKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      close();
    }
  };
  const [isHelpAndContactSidePanelOpened, setIsHelpAndContactSidePanelOpened] = useState(false);
  const [isGDPRSidePanelOpened, setIsGDPRSidePanelOpened] = useState(false);
  const [notificationSetting, setNotificationSetting] = useState({
    permissionId: invoiceNotificationSetting?.permissionId ?? permissionId,
    isEnabled: !!invoiceNotificationSetting?.isEnabled ?? false,
    notificationType: !!invoiceNotificationSetting?.notificationType ?? false,
  });

  const handleInvoiceNotificationSettingChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const prevSetting = { ...notificationSetting };
    const setting = {
      ...notificationSetting,
      [event.target.name]: event.target.checked,
    };
    setNotificationSetting(setting);
    try {
      await upsertInvoiceNotificationSetting({
        ...setting,
        permissionId: Number(setting.permissionId),
        isEnabled: Number(setting.isEnabled),
        notificationType: Number(setting.notificationType),
      });
    } catch (error: any) {
      logger.logError('Error upsertInvoiceNotificationSetting:', error);
      dispatch(setIsError(true));
      setNotificationSetting(prevSetting);
    }
  };

  const logOut = () => instance.logoutRedirect();

  const handleToggle = (e: any) => {
    e.stopPropagation();
  };

  return (
    <>
      <Popover isOpen={isOpen} close={close} anchorRef={anchorRef}>
        <MenuContainer>
          <StyledMenuList id={USER_CARD_POPOVER_MENU_ARIA_CONTROLS} onKeyDown={handleListKeyDown}>
            <StyledMenuItem onClick={() => setIsHelpAndContactSidePanelOpened(true)} disableGutters>
              <HelpAndContactIcon widthInPx={18} heightInPx={18} />
              <MenuText>
                <FormattedMessage id={userCardPopoverIntl('help-and-contact')} />
              </MenuText>
            </StyledMenuItem>
            {/* <StyledMenuItem onClick={() => setIsGDPRSidePanelOpened(true)} disableGutters>
              <GDPRIcon widthInPx={16} heightInPx={20} />
              <MenuText>
                <FormattedMessage id={userCardPopoverIntl('GDPR')} />
              </MenuText>
            </StyledMenuItem> */}
            <StyledMenuItem onClick={logOut} disableGutters>
              <StyledLogOutIcon />
              <DisconnectText>
                <FormattedMessage id={userCardPopoverIntl('disconnect')} />
              </DisconnectText>
            </StyledMenuItem>
          </StyledMenuList>
          {!isBackofficeUser && (
            <>
              <hr></hr>
              <NotificationHeader>{invoiceNotificationSettingIntl('title')}</NotificationHeader>
              <InvoiceNotificationContainer onClick={handleToggle}>
                <FormControlLabel
                  name="isEnabled"
                  control={
                    <Switch
                      color="primary"
                      checked={notificationSetting.isEnabled}
                      onChange={handleInvoiceNotificationSettingChange}
                    />
                  }
                  label={invoiceNotificationSettingIntl('invoice-notification')}
                  labelPlacement="start"
                />
                {notificationSetting.isEnabled && (
                  <InvoiceNotificationType>
                    <Grid component="label" container alignItems="center" spacing={1}>
                      <Grid item>{invoiceNotificationSettingIntl('invoice-individual')}</Grid>
                      <Grid item>
                        <Switch
                          name="notificationType"
                          color="default"
                          checked={notificationSetting.notificationType}
                          onChange={handleInvoiceNotificationSettingChange}
                        />
                      </Grid>
                      <Grid item>{invoiceNotificationSettingIntl('invoice-summary')}</Grid>
                    </Grid>
                  </InvoiceNotificationType>
                )}
              </InvoiceNotificationContainer>
            </>
          )}
        </MenuContainer>
      </Popover>
      <HelpAndContactSidePanel
        onClose={() => setIsHelpAndContactSidePanelOpened(false)}
        isOpen={isHelpAndContactSidePanelOpened}
      />
      <GDPRSidePanel
        onClose={() => setIsGDPRSidePanelOpened(false)}
        isOpen={isGDPRSidePanelOpened}
      />
    </>
  );
};
