import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { useAppDispatch } from '../../../redux/hooks';
import { getNumberOfUnreadMessages, getSelectedPermissionId } from '../../../redux/User/selectors';
import { setNumberOfUnreadMessages } from '../../../redux/User/slice';
import { getNumberOfUnreadMessageForPermission } from '../../../services/api/clientRequests/client';
import { ExchangeIcon } from '../ExchangeIcon';
import { IconPossiblyLateralProps } from '../types';
import {
  ClientRequestNotificationIconContainer,
  ClientRequestsCounterBubble,
} from './ClientRequestNotificationIcon.style';

export const ClientRequestNotificationIcon = ({
  widthInPx,
  heightInPx,
  isLateralMenuIcon,
  color,
}: IconPossiblyLateralProps): JSX.Element => {
  const selectedPermissionId = useSelector(getSelectedPermissionId);
  const numberOfUnreadMessages = useSelector(getNumberOfUnreadMessages);
  const location = useLocation();
  const dispatch = useAppDispatch();

  const fetchCountUnreadClientRequests = useCallback(async () => {
    if (selectedPermissionId && isLateralMenuIcon) {
      const countClientRequestsUnreadForPermission = await getNumberOfUnreadMessageForPermission(
        selectedPermissionId,
      );

      dispatch(
        setNumberOfUnreadMessages({
          numberOfUnreadMessages:
            countClientRequestsUnreadForPermission.countUnreadClientRequestViews,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isLateralMenuIcon, selectedPermissionId, location]);

  useEffect(() => {
    fetchCountUnreadClientRequests();
  }, [fetchCountUnreadClientRequests]);

  const numberOfMessagesToDisplay = useMemo(() => {
    const MAX_NUMBER_OF_MESSAGES_DISPLAYED = 9;

    return numberOfUnreadMessages > MAX_NUMBER_OF_MESSAGES_DISPLAYED
      ? `>${MAX_NUMBER_OF_MESSAGES_DISPLAYED}`
      : numberOfUnreadMessages;
  }, [numberOfUnreadMessages]);

  return (
    <ClientRequestNotificationIconContainer>
      <ExchangeIcon widthInPx={widthInPx} heightInPx={heightInPx} color={color}></ExchangeIcon>
      {numberOfUnreadMessages > 0 && (
        <ClientRequestsCounterBubble isLateralMenuIcon={isLateralMenuIcon}>
          {numberOfMessagesToDisplay}
        </ClientRequestsCounterBubble>
      )}
    </ClientRequestNotificationIconContainer>
  );
};
