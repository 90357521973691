import errorApiResponse from 'assets/images/ErrorApiResponse.png';
import { Button } from 'components/Button/Button';
import { getSpacing } from 'stylesheet';

import {
  ErrorApiResponseContainer,
  ErrorApiResponseDescription,
  ErrorApiResponseImage,
  ErrorApiResponseTitle,
} from './ErrorApiResponse.style';

type Props = {
  title?: string;
  description?: string;
  buttonLabel?: string;
  onButtonClick?: () => void;
};

export const ErrorApiResponse = ({
  title,
  description,
  buttonLabel,
  onButtonClick,
}: Props): JSX.Element => {
  return (
    <ErrorApiResponseContainer>
      <ErrorApiResponseImage src={errorApiResponse} />
      {title && <ErrorApiResponseTitle>{title}</ErrorApiResponseTitle>}
      {description && <ErrorApiResponseDescription>{description}</ErrorApiResponseDescription>}
      {buttonLabel && (
        <Button
          displayStyle="filledOrange"
          onClick={onButtonClick}
          bigPaddings
          margins={`${getSpacing(3)} 0 0 0`}
        >
          {buttonLabel}
        </Button>
      )}
    </ErrorApiResponseContainer>
  );
};
