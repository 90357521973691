import { ApiErrorDialog } from 'components/ApiResponseStatusGlobalDisplay/ApiErrorDialog/ApiErrorDialog';
import { FullScreenLoader } from 'components/ApiResponseStatusGlobalDisplay/FullScreenLoader/FullScreenLoader';
import { useSelector } from 'react-redux';
import { getIsError, getIsLoading } from 'redux/ApiResponseStatusGlobalDisplay/selectors';
import { setIsError, setIsLoading } from 'redux/ApiResponseStatusGlobalDisplay/slice';
import { useAppDispatch } from 'redux/hooks';

export const ApiResponseStatusGlobalDisplay = () => {
  const dispatch = useAppDispatch();
  const isLoading = useSelector(getIsLoading);
  const isError = useSelector(getIsError);

  return (
    <>
      <FullScreenLoader isVisible={isLoading} />
      <ApiErrorDialog
        isOpen={isError}
        onClose={() => {
          dispatch(setIsLoading(false));
          dispatch(setIsError(false));
        }}
      />
    </>
  );
};
